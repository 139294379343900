import React, {useContext} from 'react';
import { observer } from "mobx-react";

import {PreloadImgInstanceCTX} from "../../store/preload_img_store";

export const ThumbnailFactory = observer(({ img }: any) => {
    const { getImage, img: loaded } = useContext(PreloadImgInstanceCTX)

    const image = getImage(img.path)

    return <img
        src={image ? image?.default : loaded[img]}
        alt={img.name}
        {...img}
    />
})
