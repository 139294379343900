import React, {useContext} from "react";
import { useHistory } from 'react-router-dom';
import { Typography, TypographyVariants, PrimaryButton } from "@ruby-labs/ruby-web-common";

import {BaseContext} from "../../helpers/context/base.context";
import waitImg from '../../assets/img/wait_img.svg';
import { useStyles } from "./styles";

export const Wait = () => {
    const history = useHistory();
    const { analytic } = useContext(BaseContext);
    const { title, text, button, wrapper, img } = useStyles();

    const handleSubmit = () => {
        analytic?.event("WinBackWebWaitGotItClicked");
        history.push('/access');
    }

    return (
        <div className={wrapper}>
            <img className={img} src={waitImg} alt=""/>
            <Typography className={title} variant={TypographyVariants.h2}>
                Sit tight!
            </Typography>
            <Typography variant={TypographyVariants.body1} className={text}>
                We are wrapping your premium bundle <span>up to deliver it to your e-mail within the next 30 minutes or less.</span> After all, good things (and premium bundles!) come to those who wait.
            </Typography>
            <PrimaryButton
                onClick={handleSubmit}
                className={button}
                label="Next"
            />
        </div>
    )
};
