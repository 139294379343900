import { AnalyticsBase } from "../abstract/analytic_base.abstract";

export interface AnalyticsServiceInterface {
    event: (name: string, params: any) => void
}

class AnalyticsService extends AnalyticsBase implements AnalyticsServiceInterface {

    get fbq() {
        return {
            track: (name: string, data: any) => {
                window.fbq('track', name, data);
            }
        };
    }


    public event(name: string, params = {}) {
        void super.baseEvent(name, params)

        if (['OptimizelyExperimentViewed', 'OnboardingViewed'].includes(name)) return;

        try {
            this.optimizely?.onReady().then(() => {
                this.optimizely.track(name, this.userId);
            });
        } catch (error: any) {
            this.optimizely?.onReady().then(() => {
                this.optimizely.track(name, this.userId);
            });
        }
    }
}

export const analytics_service = () => new AnalyticsService();