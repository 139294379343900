import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    buttonWrapper: {
        padding: '40px 16px',
        maxWidth: 428,
        margin: '0 auto',
        boxSizing: 'border-box',
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        background: "white",
        width: '100%'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 48,

        '& p':{
            fontSize: '16px'
        }
    }
});
