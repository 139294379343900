import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    chartWrapper: {
        width: '100%',
        marginBottom: 49,
        height: 248
    },
    lineChart: {
        '& .recharts-yAxis': {
            display: 'none '
        },
        '& .recharts-xAxis': {
            '& .recharts-text': {
                counterIncrement: 'n',
                fill: '#6B6B6B',
                fontSize: 16,
                fontFamily: "SF Pro Text",
            },
            '& .recharts-cartesian-axis-tick': {
                '&:nth-last-child(-n + 2)': {
                    fontWeight: 600,
                    '& .recharts-text': {
                        fill: '#202B47',
                    }
                },
            },
        },
        '& .recharts-cartesian-grid': {
            opacity: 0,
            transition: 'opacity 1.5s',
            transitionDelay: '.5s'
        },
        '& .recharts-cartesian-axis-ticks': {
            opacity: 0,
            transition: 'opacity 1.5s',
            transitionDelay: '.5s',
            counterReset: 'n',
        },
        '& .recharts-label-list': {
            '& >g': {
                '& rect': {
                    fill: 'none'
                }
            }
        }
    },
    active: {
        '& .recharts-cartesian-grid': {
            opacity: 1
        },
        '& .recharts-cartesian-axis-ticks': {
            opacity: 1
        }
    }
})
