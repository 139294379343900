import React from "react";
import {
    Typography,
    TypographyVariants,
    PrimaryButton,
    localStorageService
} from "@ruby-labs/ruby-web-common";

import {
    valueGetter,
    longValueGetter,
    GoalTypes,
    WEIGHT_LOSE_BODY_GOAL,
    GAIN_MUSCLE_BODY_GOAL
} from "./config";
import triangleIcon from '../../../assets/svg/triangle_icon.svg';
import cupIcon from '../../../assets/svg/cup.svg';
import {useStyles} from "./styles";

type ModalContentProps = {
    handleClose: () => void
}

export const ModalContent = ({ handleClose }: ModalContentProps) => {
    const {
        wrapper, title, subtitle, textWrapper, planLabel, finalText,
        triangle, label, labelWrapper, imageContent, ableLabel, continueButton, gainWrapper
    } = useStyles();
    const goal = localStorageService.getItem('body-goal') || WEIGHT_LOSE_BODY_GOAL;
    const longText = longValueGetter[goal as GoalTypes];
    const shortText = valueGetter[goal as GoalTypes];
    const labelWrapperClass = `${labelWrapper} ${ GAIN_MUSCLE_BODY_GOAL === goal ? gainWrapper : ''}`

    return (
        <div className={wrapper}>
            <Typography className={title} variant={TypographyVariants.h2}>
                Did you know?
            </Typography>
            <Typography className={subtitle} variant={TypographyVariants.h4}>
                Able members who start their journey with our Premium Bundle <span>{longText}</span>
            </Typography>
            <div className={imageContent}>
                <div className={labelWrapperClass}>
                    <div className={textWrapper}>
                        <img className={triangle} src={triangleIcon} alt=""/>
                        <Typography className={label} variant={TypographyVariants.h4}>
                            {shortText}
                        </Typography>
                    </div>
                    <img src={cupIcon} alt=""/>
                </div>
                <div className={planLabel}>
                    Able + Plan
                </div>
                <div className={ableLabel}>
                    Able
                </div>
            </div>
            <Typography variant={TypographyVariants.h4} className={`${subtitle} ${finalText}`}>
                We want you to be satisfied, so we’re offering <span>25% off</span> Premium Bundle, just for you!
            </Typography>
            <PrimaryButton
                onClick={handleClose}
                className={continueButton}
                label="Continue"
            />
        </div>
    )
}
