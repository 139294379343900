export function evaluateExpressionTree(tree: any): any {
    //#TODO here we able extend or logic for transpile string logical expression to real one
    if (tree.type == "Literal") {
        return tree.value;
    }
    else if (tree.type == "LogicalExpression") {
        if (tree.operator == "&&") {
            return evaluateExpressionTree(tree["left"]) && evaluateExpressionTree(tree["right"]);
        }
        else {
            return evaluateExpressionTree(tree["left"]) || evaluateExpressionTree(tree["right"]);
        }
    }
    else if (tree.type == "BinaryExpression") {
        if (tree.operator == "==") {
            return evaluateExpressionTree(tree["left"]) == evaluateExpressionTree(tree["right"]);
        }
    }
    else {
        // Unexpected node parsed
        return null;
    }
}