import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {PriceBlock, Typography, TypographyVariants} from "@ruby-labs/ruby-web-common";

import loadingImgUrl from '../../assets/animations/loading-bluebg.json';
import {BaseContext} from "../../helpers/context/base.context";
import {CouponsApiProvider} from "../../api/coupons.api";
import {useStyles} from "./styles";
import {Animation} from "../../components/animation";

const COUPON_ID = 'UPGRADEPLAN-33';
const PLAN_1_WEEK_PRICE = 19;
const DISCOUNT_PERCENTAGE = 0.33;

export const TrialSkip = () => {
    const [loader, setLoader] = useState(false);
    const { analytic } = useContext(BaseContext);
    const history = useHistory()

    const onSubmit = () => {
        const email = localStorage.getItem('email') as string;

        setLoader(true);

        CouponsApiProvider.upgradePlan({
            email,
            coupon: COUPON_ID
        })
            .then((res: any) => analytic?.event("WinBackWebSkipTrialStartPlanClicked", {status: res.status}))
            .catch((res: any) => analytic?.event("WinBackWebSkipTrialStartPlanClicked", {status: res.status}))
            .finally(() => {
                setLoader(false);
                history.push('/addons')
            })
    }


    const skip = (top: boolean = false) => () => {
        if (loader) return;

        const eventName = top ? 'WinBackWebSkipTrialSkipTopClicked' : 'WinBackWebSkipTrialStartTrialClicked';

        analytic?.event(eventName);

        history.push('/addons')
    }

    useEffect(() => {
        analytic?.event('WinBackWebSkipTrialViewed');
    }, [])

    const discountPrice = PLAN_1_WEEK_PRICE * DISCOUNT_PERCENTAGE;
    const finishPrice = PLAN_1_WEEK_PRICE - discountPrice;

    const {
        page,
        textBreak,
        title,
        text,secondCard,
        cards, firstCard,
        skipWrapper, skipButton
    } = useStyles();

    const btnLabel = loader
        ? <Animation width="50" height="50" url={loadingImgUrl} loop={true} />
        : 'Skip trial and start plan'

    return (
        <>
            <div className={page}>
                <div className={skipWrapper}>
                    <Typography
                        //@ts-ignore
                        onClick={skip(true)}
                        variant={TypographyVariants.body1}
                        className={skipButton}
                    >
                        Skip {'>'}
                    </Typography>
                </div>
                <Typography variant={TypographyVariants.title} className={title}>
                    Not planning on looking back?
                </Typography>
                <Typography variant={TypographyVariants.body1} className={text}>
                    Get a head start and take <span>{DISCOUNT_PERCENTAGE * 100}% off</span> your
                    <br className={textBreak}/> first 1-week plan by skipping the trial and
                    <br className={textBreak}/> starting today!
                </Typography>
                {!!discountPrice && (
                    <div className={cards}>
                        <PriceBlock
                            // @ts-ignore
                            totalSavingText="Total savings"
                            btnLabel={'Start trial'}
                            active={false}
                            trialIcon={false}
                            price={''}
                            currency={''}
                            //@ts-ignore
                            priceText={(<>${PLAN_1_WEEK_PRICE} for <br /> 1-week plan</>)}
                            savingPrice={'$0'}
                            trialTitle={'7-day trial'}
                            className={firstCard}
                            onClick={skip()}
                        />
                        <PriceBlock
                            // @ts-ignore
                            totalSavingText="Total savings"
                            btnLabel={btnLabel}
                            active={true}
                            trialIcon={false}
                            price={''}
                            currency={''}
                            //@ts-ignore
                            priceText={(<>${finishPrice.toFixed(2) || 0} for <br /> 1-week plan</>)}
                            savingPrice={`$${discountPrice.toFixed(2) || 0}`}
                            trialTitle='7-day trial'
                            onClick={onSubmit}
                            className={secondCard}
                            label={'SAVE 33%'}
                        />
                    </div>
                )}
            </div>
        </>
    )
}