import React from "react";

import {
  PrimaryButton,
  Typography,
  TypographyVariants,
  ModalWindow
} from "@ruby-labs/ruby-web-common";

import { PriceBox } from "./price_box";
import { DiscountBlock } from "./discount_block";
import { ModalContent } from "./modal_content";

import { useAddon } from "./addon.hook";
import { useStyles } from "./styles";

const upsellPricingData = {
  price: 19,
  originalPrice: 45,
  discountPrice: 15,
  prefix: '',
  prefixDiscount: ''
}

export const Addons = () => {
  const {
    wrapper, skipButton, skipWrapper, title, button,
    subtitle, tryItTitle, buttonContent, icon,
  } = useStyles();
  const { originalPrice, discountPrice, price, prefix, prefixDiscount } = upsellPricingData;
  const {
    isDiscount, loading, open,
    skip, onSubmit, handleClose
  } = useAddon({ prefix, prefixDiscount });

  return (
      <div className={wrapper}>
        <div className={skipWrapper}>
          <Typography
              //@ts-ignore
              onClick={skip}
              variant={TypographyVariants.body1}
              className={skipButton}
          >
            Skip {'>'}
          </Typography>
        </div>
        <Typography className={title} variant={TypographyVariants.h2}>
          Get extra help with our Premium Bundle
        </Typography>
        <Typography className={subtitle} variant={TypographyVariants.h4}>
          Exclusive offer recommended for you to achieve your goals faster.
        </Typography>
        <PriceBox
            price={price}
            discountPrice={discountPrice}
            originalPrice={originalPrice}
        />
        {isDiscount && (
            <>
              <DiscountBlock />
              <Typography className={tryItTitle} variant={TypographyVariants.title}>
                Try it out for only <s>${originalPrice}</s> ${discountPrice}
              </Typography>
            </>
        )}
        <PrimaryButton
            loading={loading}
            onClick={onSubmit}
            className={button}
            // @ts-ignore
            label={(
                <span className={buttonContent}>
                        <svg
                            className={icon}
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M12.6485 7.33301H4.35218C3.69762 7.33301 3.16699 7.92996 3.16699 8.66634V13.333C3.16699 14.0694 3.69762 14.6663 4.35218 14.6663H12.6485C13.303 14.6663 13.8337 14.0694 13.8337 13.333V8.66634C13.8337 7.92996 13.303 7.33301 12.6485 7.33301Z" fill="white" stroke="white" stroke-width="0.833333" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.16699 7.33301V4.66634C5.16699 3.78229 5.51818 2.93444 6.1433 2.30932C6.76842 1.6842 7.61627 1.33301 8.50033 1.33301C9.38438 1.33301 10.2322 1.6842 10.8573 2.30932C11.4825 2.93444 11.8337 3.78229 11.8337 4.66634V7.33301" stroke="white" stroke-width="0.833333" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Buy Now
                    </span>
            )}
        />
        <ModalWindow open={isDiscount && open}>
          <ModalContent handleClose={handleClose} />
        </ModalWindow>
      </div>
  )
};
