import {makeAutoObservable} from "mobx"
import {createContext} from "react";

const backgroundLoadingImages = [
    'img/progres_weigth_1.png',
    'img/progres_weigth_3.png',
    'img/Female-weightloss.png',
    'img/progres_weigth_2.png',
    'img/Male-weightloss.png',
    'img/Male-Musclegain.png',
    'img/Female-weightloss.png',
    'img/Female-Musclegain.png',
    'img/Female-Musclegain.png',
    'svg/strawberry.svg',
    'svg/triangle.svg',
    'svg/pear.svg',
    'svg/hourglass.svg',
    'svg/ectomorph.svg',
    'svg/mesomorph.svg',
    'svg/endomorph.svg',
    'svg/cheese.svg',
    'svg/tofu.svg',
    'svg/fish.svg',
    'svg/meat.svg',
    'svg/eggs.svg',
    'svg/nuts.svg',
    'svg/peas.svg',
    'svg/zucchini.svg',
    'svg/mushrooms.svg',
    'svg/tomatoes.svg',
    'svg/peppers.svg',
    'svg/avocado.svg',
    'svg/body-diet.svg',
    'svg/body-diet-getfit.svg',
    'svg/body-diet-musclegain.svg',
]

class PreloadImg {
    constructor() {
        makeAutoObservable(this)
        backgroundLoadingImages.map((link: string) => this.loadImage(link))
    }

    img: { [key: string]: any } = {}
    loadedDestination: any = []

    private getLinkFromConfig = (config: any): string | undefined => {
        return config?.thumbnails?.img?.path
    }

    private async loadImage(path: string) {
        try {
            if (!path || this.img[path]) return
            const image = await import(`../assets/${path}`)

            const loading = new Image();
            loading.src = image.default;

            this.img[path] = image;
        } catch (e) {}
    }

    getImage = (path: string) => {
        if(!path) return;
        if (!this.img[path]) void this.loadImage(path)

        return this.img[path];
    }

    startPreloadImage = async (config: any, destination: string) => {
        try {
            const link: string | undefined = this.getLinkFromConfig(config)

            if (!link || this.img[link]) return;

            await this.loadImage(link)

            this.loadedDestination = [...this.loadedDestination, destination]
        } catch (e) {}
    }
}

export const PreloadImgInstance = new PreloadImg();
export const PreloadImgInstanceCTX = createContext(PreloadImgInstance);

