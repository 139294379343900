import React, { memo, useState, useEffect } from 'react';
import { Dot } from "recharts";
import { useLocalStorage } from '@ruby-labs/ruby-web-common';

import { useStyles } from "./styles";

export const CustomizedDot = memo((props: any) => {
    const [showed, setShowed] = useState(false);
    const { index: currentDot, dotsLength } = props;
    const { pageValue: goal }: any = useLocalStorage({ key: 'body-goal', defaultValue: '' });

    const { dot, active} = useStyles({isLast: goal === 'get fit' ? currentDot === 1 : currentDot === dotsLength - 2});

    useEffect(() => {
        setShowed(true);
    }, []);

    // hide first and last dot on graph
    if(!currentDot || dotsLength - 1 === currentDot) return null;

    const className = `${dot} ${showed ? active : ''}`;

    return <Dot {...props} r={goal === 'get fit' ? currentDot === 1 ? 9 : 5 :currentDot === dotsLength - 2 ? 9 : 5} className={className} />
});
