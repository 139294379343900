import React, {useContext, useEffect} from 'react';
import {Typography, TypographyVariants} from "@ruby-labs/ruby-web-common";
import {useHistory} from "react-router-dom";

import {BaseContext} from "../../helpers/context/base.context";
import {useStyles} from "./styles";

export const Congrats = () => {
    const history = useHistory()
    const { analytic } = useContext(BaseContext);

    useEffect(() => {
        analytic?.event("WinBackWebCongratsViewed");

        setTimeout(() => {
            history.push('/trialskip')
        }, 4000)
    }, [history])

    const { content, title, text, textBreak } = useStyles()

    return (
        <div>
            <div className={content}>
                <Typography
                    variant={TypographyVariants.title}
                    className={title}
                >
                    Congratulations!
                </Typography>
                <Typography
                    variant={TypographyVariants.body1}
                    className={text}
                >
                    We’re excited to be a part of your health <br className={textBreak}/> journey. Please do not click back or <br className={textBreak}/> refresh the page while we finish creating <br className={textBreak}/> your account.
                </Typography>
            </div>
        </div>
    )
}