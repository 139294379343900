import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '80px 24px 20px'
    },
    headerSkip: {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#4B46EC',
        alignSelf: 'flex-end',
        cursor: 'pointer',
    },
    title: {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: '-0.0024em',
        color: '#202B47',
        fontSize: 20,
        lineHeight: '24px'
    },
    text: {
        marginTop: '12px',
        fontFamily: 'SF Pro Text',
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '-0.0024em',
        color: '#202B47',
        textAlign: 'center',
        '& span': {
            color: '#4B46EC',
            fontWeight: 600
        },
    },
    cards: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '56px',
        width: '100%',
    },
    firstCard: {
        marginRight: '4px',
        paddingTop: '16px',
        alignItems: 'flex-start',

        '& > div > p:first-child': {
            fontWeight: 600,
            fontSize: '22px',
            lineHeight: '30px'
        },
        '& > div > p:nth-child(4)': {
            fontSize: '16px',
            lineHeight: '24px'
        },
        '& > div > p:nth-child(3)': {
            fontWeight: 400,
        },
        '& > div > p:nth-child(5)': {
            fontWeight: 400,
        },
        '& button': {
            height: 56,
            marginTop: 12,
            color: '#868C99',
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '& p': {
                fontSize: 14,
                fontWeight: 600,
            },
        }
    },
    secondCard: {
        marginLeft: '3px',
        paddingTop: '16px',
        alignItems: 'flex-start',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        '& > div > p:first-child': {
            fontSize: '22px',
            lineHeight: '30px'
        },
        '& > div > p:nth-child(4)': {
            fontSize: '16px',
            lineHeight: '24px'
        },
        '& > div > p:nth-child(3)': {
            fontWeight: 400,
        },
        '& > div > p:nth-child(5)': {
            fontWeight: 400,
        },
        '& > p': {
            top: -32
        },
        '& button': {
            paddingLeft: 10,
            paddingRight: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: 56,
            height: 56,
            '& p': {
                fontSize: 14,
                fontWeight: 600,
            },

            marginTop: 12
        }
    },
    textBreak: {
        display: 'block',
    },
    skipWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    skipButton: {
        display: 'inline-block',
        color: '#4B46EC',
        borderRadius: 20,
        padding: '3px 10px',
        cursor: 'pointer',
        background: '#F6F6F6',
        margin: '0 0 24px',
        fontWeight: 600
    },
    '@media (max-width: 365px)' : {
        textBreak: {
            display: 'none'
        },
        firstCard: {
            '& > div': {
                boxSizing: 'border-box',
                padding: '0 10px'
            }
        },
        secondCard: {
            '& > div': {
                boxSizing: 'border-box',
                padding: '0 10px'
            }
        }
    },
});
