import React from 'react';

import { Review } from "./review";

import { reviewsConfig } from './config';
import { useStyles } from "./styles";

type ReviewType = {
    name: string;
    avatar: string;
    review: string | (() => {});
}

export const CustomerReviews = () => {
    const { header, wrapper } = useStyles();

    return (
        <div className={wrapper}>
            <div className={header}>
                Customer reviews
            </div>
            {reviewsConfig.map((review: ReviewType) => <Review {...review} />)}
        </div>
    )
}
