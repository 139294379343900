import {useEffect} from "react";
import {useLocalStorage} from "./local_storage.hook";

declare global {
    interface Window {
        geoip2: any
    }
}

const COUNTRY_KEY = 'utm_country'

export const useCountryLocation = () => {
    const {pageValue: country, setPageValue: setCountry} = useLocalStorage({ key: COUNTRY_KEY, defaultValue: '' });

    useEffect(() => {
        window.geoip2?.country(({ country }: any) => {
            const countryCode = country.iso_code
            if (countryCode) setCountry(countryCode)
        }, () => {
            window.geoip2?.country(({ country }: any) => {
                const countryCode = country.iso_code
                if (countryCode) setCountry(countryCode)
            })
        })
    }, [setCountry])

    return { country }
}
