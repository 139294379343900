import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    title: {
        lineHeight: '28px',
        fontWeight: 600,
        textAlign: 'center',
        maxWidth: 250,
        margin: '0 auto 8px',
        letterSpacing: '0.7px',
        color: '#202B47'
    },
    subtitle: {
        textAlign: 'center',
        fontWeight: 400,
        maxWidth: 340,
        margin: '0 auto 16px auto',
        color: '#202B47'
    },
    wrapper: {
        padding: '56px 16px 0 16px',
    },
    skipWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    skipButton: {
        display: 'inline-block',
        color: '#4B46EC',
        borderRadius: 20,
        cursor: 'pointer',
        background: '#F6F6F6',
        margin: '24px 0 24px 0',
        padding: '3px 10px',
        fontWeight: 600
    },
    tryItTitle: {
        fontSize: 18,
        lineHeight: '22px',
        paddingBottom: 15,
        color: '#F75C03'
    },
    buttonContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600
    },
    icon: {
        marginRight: 10
    },
    button: {
        height: 48,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        marginTop: 24,
        marginBottom: 12,
        margin: '24px 0 12px',
    }
});
