import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #eff2fd',
        borderRadius: '4px',
        marginBottom: 8,
        padding: 12,
        '&:last-child': {
            marginBottom: 0
        }
    },
    typ: {
        marginTop: '2px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        fontFamily: 'SF Pro Text',
        color: '#44588e',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'start'
    },
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    customerImg: {
        width: 32,
        height: 32,
        borderRadius: '16px'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: 12
    },
    personIcon: {
        marginLeft: 6
    },
    customReviewRecommended: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content'
    },
    typ2: {
        marginLeft: 6,
        color: '#8e8e93',
        fontSize: '12px',
        lineHeight: '18px'
    },
    hintWrapper: {
        color: '#000',
        marginLeft: 4
    },
    comment: {
        marginTop: 6,
        fontSize: 12,
        lineHeight: '16px',
        textAlign: 'left'
    }
});
