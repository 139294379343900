import { createUseStyles } from "react-jss";

interface propsInterface {
    isLast: boolean
}

export const useStyles = createUseStyles({
    dot: {
       opacity: 0,
       transition: 'opacity 1.5s',
       stroke: ({isLast}: propsInterface) => isLast ? '#FEBD98' : '',
       strokeWidth: ({isLast}: propsInterface) => isLast ? 5 : 0,
    },
    active: {
       opacity: 1
    },
});
