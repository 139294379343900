import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    subscribeWrapper: {
        paddingBottom: 89,
        paddingTop: 70,
        '& *': {
            color: '#121620'
        }
    },
    hidden: {
        display: 'none'
    },
    paymentWrapper: {
        display: 'none'
    },
    showed: {
        display: 'block'
    },
    content: {
        padding: '0 16px',
        marginBottom: 87
    }
});
