import React from "react";

import {Typography, TypographyVariants} from "@ruby-labs/ruby-web-common";

import { useStyles } from "./styles";

export const HeaderBlock = () => {
    const {
        header, paymentHeader, headerTitleColored,
        headerContent, headerTitleBold
    } = useStyles();

    return (
        <div className={paymentHeader}>
            <div className={header}>
                <Typography className={headerContent} variant={TypographyVariants.body1}>
                    <span className={headerTitleBold}>Special Offer:</span> Get 30% off + 7 days trial with code <span className={headerTitleColored}>ABLE22</span>
                </Typography>
            </div>
        </div>
    )
}