import { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';

import { SliderPage, useLocalStorage } from '@ruby-labs/ruby-web-common';
import { setInterval } from "timers";
import { BaseContext } from "../../helpers/context/base.context";

const GOAL_VALUES = {
    gainMuscle: 'gain muscle',
    getFit: 'get fit',
    loseWeight: 'lose weight'
}

export const Finisher = () => {
    const { analytic, isPlanValid, subscribed } = useContext(BaseContext);
    const history = useHistory();
    const { planid } = queryString.parse(window.location.search);

    const onFinished = () => {
        if(subscribed) {
            return history.push('/access', {})
        }

        history.push(isPlanValid ? '/subscribe' : '/trial-pay');
    }

    useEffect(() => {
        analytic?.event(`WinBackWebFinisher1Viewed`)
        let i = 2
        const interval = setInterval(() => {
            if (i === 5 || history.location.pathname !== '/') {
                clearInterval(interval)
            } else {
                analytic?.event(`WinBackWebFinisher${i}Viewed`)
                i++
            }
        }, 4500)
    }, [history.location.pathname]);

    const { pageValue: bodyGoal }: any = useLocalStorage({ key: 'body-goal', defaultValue: '' });
    //@ts-ignore
    const goal = GOAL_VALUES[bodyGoal] || '';

    if (!planid) {
        history.push('/access')
        return null
    }

    return (<SliderPage
        textColor="#FFFFFF"
        background={"linear-gradient(40.19deg, #FF6814 4.83%, #FEBA2C 100%)"}
        elements={[
            {
                value: "Based on your \n answers",
                delay: 3000
            },
            {
                value: "we’ve created \n a personalized plan",
                delay: 3000
            },
            {
                value: `to help you ${goal.split(' ').join('\n')}`,
                delay: 3000
            },
            {
                value: "Let’s get started.",
                delay: 3000
            }
        ]}
        onFinished={onFinished}
    />);
}