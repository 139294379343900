import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    page: {
        padding: '30px 28px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginBottom: '24px'
    },
    title: {
        fontFamily: 'SF Pro Display',
        fontSize: '20px',
        lineHeight: '130%',
        letterSpacing: '0.2px',
        color: '#121620',
        marginBottom: '24px',
    },
    list: {
        position: 'relative',
        counterReset: 'num',
        width: '100%',
        padding: '0 28px',
        textAlign: 'left',
    },
    listItem: {
        marginBottom: '12px',
        fontFamily: 'SF Pro Text',
        fontStile: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.0024em',
        color: '#202B47',
        counterIncrement: 'num',
        paddingLeft: '40px',
        display: 'flex',
    },
    listItemContent: {
        fontFamily: 'SF Pro Display',
        fontSize: '20px',
        lineHeight: '130%',
        letterSpacing: '0.2px',
        marginLeft: '-35px',
        marginRight: '22px',
        color: '#4B46EC',
        fontWeight: 600,
        width: '10px'
    },
    loginImage: {
        marginLeft: 'calc((100% - 295px)/2)',
    },

    emailContinue: {
        paddingLeft: '21px'
    },

    continueImage: {
        marginLeft: 'calc((100% - 329px)/2)',
    },

    downloadButton: {
        textDecoration: 'none',
        width: '100%',
        marginTop: '12px',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#FFFFFF',
        letterSpacing: '1px',
        padding: '0 18px',
        height: '48px',
    },
    boldText: {
        fontWeight: 700,
    },
    textBreak: {
        display: 'block',
    },
    '@media (max-width: 365px)' : {
        textBreak: {
            display: 'none',
        }
    },
})