const axios = require('axios');

axios.defaults.timeout = 10000;
axios.defaults.headers.common['Content-Type'] = 'application/json';

console.log(process.env);
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000,
});

export class WrapperApi {
    get(action: string, params?: any, version?: number): Promise<any> {
        const search_params = new URLSearchParams();

        for (let key in params) search_params.append(key, params[key]);

        const url = `/api/v${version || '1'}/${action}?${search_params}`;

        return instance.get(url);
    }

    post(action: string, data: any, apiVersion = 1): Promise<any> {
        try {
            const body = JSON.stringify(data);
            const url = `/api/v${apiVersion}${action}`;
            return instance.post(url, body);
        } catch (err) {
            return Promise.reject(err.message);
        }
    }
}
