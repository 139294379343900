import React from 'react';

import {Typography, TypographyVariants} from "@ruby-labs/ruby-web-common";

import { useStyles } from "./styles";

export const InfoBlock = () => {
    const { title, infoBlock, subtitle } = useStyles();

    return (
        <div className={infoBlock}>
            <Typography className={title} variant={TypographyVariants.h3}>
                Hey,
            </Typography>
            <Typography className={subtitle} variant={TypographyVariants.body1}>
                Based on your answers, psychological and phisiological parameters, <span>we prepared your personalized plan.</span>
            </Typography>
        </div>
    );
};
