import React, { useEffect, useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite"
import { toJS } from "mobx";

import { Layout } from "../../components/layout";
import { Generic } from "../../components/generic";
import { GenericInstanceCTX } from "../../store/generic_store";

import { componentConfigFile } from "../../utils/component_config_file";

export const GenericPage = observer(() => {
    const { path } = useParams<{path: string}>();
    const { getLazyGenericData, data, pageDataNotExist, clearPageData }: any = useContext(GenericInstanceCTX);

    useEffect(() => {
        getLazyGenericData(path)
    }, [path, getLazyGenericData])

    useEffect(() => clearPageData, [clearPageData])

    if (pageDataNotExist) return <Redirect to="/" />

    return (
        <Layout isProgressBarActive={!data?.disable_progress_bar}>
                <Generic
                    pageConfigFile={toJS(data)}
                    componentConfigFile={componentConfigFile}
                />
        </Layout>
    )
})
