import {WrapperApi} from "./wrapper.api";

interface AddonInterface {
    email: string | null;
    addon: string | number;
}

interface PlanInterface {
    email: string;
    coupon: string | number;
}


class CouponsApi extends WrapperApi{
    upgradeAddon(data: any): Promise<any> {
        return this.post('/chargebee/charge', {...data, marketingCampaign: 'mailWinback'}, 1);
    }
    upgradePlan(data: PlanInterface): Promise<any> {
        return this.post('/chargebee/upgrade', {...data, marketingCampaign: 'mailWinback'}, 1);
    }
}

export const CouponsApiProvider = new CouponsApi();
