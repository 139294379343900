import liz from '../../../assets/img/reviews/liz.png';
import mich from '../../../assets/img/reviews/Michael.png';
import melisa from '../../../assets/img/reviews/melisa.png';

export const planGoalValues: any = {
    'loseWeight': 'lost 12 lb',
    'gainMuscle': 'gained 12lb of muscle',
    'getFit': 'lost 12 lb of fat'
}

export const reviewsConfig = [
    {
        name: "Liz Hoffman",
        avatar: liz,
        review: "I signed up during the end of May for the rock-bottom price for a whole year and I have to say I absolutely LOVE the program. "
    },
    {
        name: "Michael",
        avatar: mich,
        review: () => {
            const goal = localStorage.getItem('body-goal');

            // @ts-ignore
            return  `I’ve ${planGoalValues[goal]} BUT the best is the psychological tips/tricks. I am a self-professed book-nerd, so trust me when I tell you they have done their homework!`
        }
    },
    {
        name: "Melisa Taylor",
        avatar: melisa,
        review: 'I was amazed how in a few days, I began losing near a pound a day. That may not seem like much, but my new normal weight stays off like that even now, 3 months after the program.'
    }
]