import React from 'react';

import { Typography, TypographyVariants } from "@ruby-labs/ruby-web-common";

import mealIcon from '../../../assets/svg/meal_icon.svg';
import heartIcon from '../../../assets/svg/heart_icon.svg';
import { useStyles } from "./styles";

const PRICE_SALE = 58;
const PRICE_SALE_DISCOUNT = 67;

type PriceBoxType = {
    price: number
    originalPrice: number,
    discountPrice: number
}

export const PriceBox = ({
    price,
    discountPrice,
    originalPrice
}: PriceBoxType) => {
    const {
        wrapper, text, block, title, blockImg, keepPlan,
        oneTimePriceWrapper, oneTimePrice, originalPriceClass
    } = useStyles();

    const isDiscount = window.location.pathname === '/addon-discount';
    const pricing = isDiscount ? discountPrice : price;
    const savedPercents = isDiscount ? PRICE_SALE_DISCOUNT : PRICE_SALE;

    return (
        <div className={wrapper}>
            <Typography className={title} variant={TypographyVariants.h3}>
                What your premium bundle will include:
            </Typography>
            <div className={block}>
                <img className={blockImg} src={mealIcon} alt=""/>
                <Typography className={text} variant={TypographyVariants.h4}>
                    Custom meal plan
                </Typography>
            </div>
            <div className={block}>
                <img className={blockImg} src={heartIcon} alt=""/>
                <Typography className={text} variant={TypographyVariants.h4}>
                    Custom self-care plan
                </Typography>
            </div>
            <div className={oneTimePriceWrapper}>
                <Typography className={oneTimePrice} variant={TypographyVariants.h4}>
                    One-time price of ${pricing}!
                </Typography>
                <Typography className={originalPriceClass} variant={TypographyVariants.h4}>
                    Original price is ${originalPrice}. Save {savedPercents}%!
                </Typography>
            </div>
            {!isDiscount && (
                <Typography variant={TypographyVariants.h4} className={keepPlan}>
                    These plans are <span>yours to keep</span> even if you decide Able isn’t right for you.
                </Typography>
            )}
        </div>
    )
}
