import keys from 'lodash/keys';

import { getLocalStorageData } from "../helpers/services/local_storage_data_parser.service";

export const SIGN_UP_API_KEYS_VALUES = {
    email: 'email',
    planCode: 'planCode',
    'body-gender': 'gender',
    'body-goal': 'goal',
    'body-idealweight': 'weightGoal',
    'body-age': 'age',
    'habits-activity': 'activity',
    'nutrition-pace': 'weightLossSpeed',
    'unit': 'unit',
    'body-weight': 'weightCurrent',
    'body-height': 'height'
};

const NUMBER_KEYS = {
    'body-height': true,
    'body-height-in': true,
    'body-weight': true,
    'body-idealweight': true
}

const DEFAULT_VALUES = {
    currency: 'USD',
    lastName: ''
}

const getSignUpDataFromLocalStorage = () => {
    const localStorageKeys = keys(SIGN_UP_API_KEYS_VALUES);
    const data = getLocalStorageData(localStorageKeys);
    const apiData = localStorageKeys.reduce((apiData: any, key: string) => {
        let value = data[key];

        if(!value) return apiData;

        // @ts-ignore
        apiData[SIGN_UP_API_KEYS_VALUES[key]] = NUMBER_KEYS[key] ? Number(value) : value;

        return apiData;
    }, {})

    return apiData;
}


// @param:
//   - additionalData - object with post data not related to local storage
export const getSignUpData = (additionalData: any = {}, gPay?: boolean) => {
    const data = getSignUpDataFromLocalStorage();

    if (!gPay) {
        data.name = additionalData.name;
    }

    return {
        ...data,
        ...DEFAULT_VALUES,
        ...additionalData
    }
}