import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    specialOffer: {
        color: '#36B992',
        border: '1px solid #36B992',
        padding: '8px 10px',
        width: 110,
        borderRadius: '10px',
        boxSizing: 'border-box',
        fontSize: 14,
        fontWeight: 500,
        margin: '0 auto',
        marginBottom: 24,
        lineHeight: '17px',
        marginTop: 32
    },
    title: {
        fontSize: '18px',
        lineHeight: '24px',
        marginBottom: '15px',
        color: '#202B47'
    },
    subtitle: {
        paddingBottom: 24,
        borderBottom: '1px solid #C7C7C7',
        color: '#202B47',
        fontSize: 14
    },
    totalPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '11px 0',
        borderBottom: '1px solid #C7C7C7',
        color: '#202B47'
    },
    totalPriceClass: {
        fontWeight: 600,
        fontSize: 14,
        color: '#202B47'
    },
    totalPriceCount: {
        fontWeight: 700,
        color: '#4B46EC',
        fontSize: '14px',
        lineHeight: '18px'
    },
    codeHint: {
        color: '#36B992',
        margin: '6px 0 6px 0',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px'
    },
    costTwoWeekPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left'
    },
    costTwoWeeksText: {
        fontSize: '14px',
        lineHeight: '18px',
        margin: '6px 0 2px',
        letterSpacing: '0.2px',
        color: '#202B47'
    },
    firstPrice: {
        color: '#858da5',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        marginLeft: '21px'
    },
    secondPrice: {
        fontWeight: 600,
        marginLeft: 4,
        fontSize: '14px',
        lineHeight: '18px'
    },
    saveText: {
        color: '#8E8E93',
        fontWeight: 400,
        textAlign: 'left',
        paddingBottom: 12,
        borderBottom: '1px solid #C7C7C7',
        fontSize: '14px',
        lineHeight: '18px'
    },
    chargedText: {
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '18px',
        margin: '12px 0 12px 0',
        color: '#202B47',
        '&>span': {
            fontWeight: 500
        }
    }
});
