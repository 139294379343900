import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    content: {
        width: 311,
        borderRadius: 10,
        background: '#ffffff',
        padding: '51px 19px',
        boxSizing: 'border-box'
    },
    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 46
    },
    btn: {
        minWidth: 'auto',
        width: 126,
        '&>p': {
            fontWeight: '600',
        }
    }
});
