import React from "react";

import {Typography, TextNormalizer, TypographyVariants} from "@ruby-labs/ruby-web-common";

import reviewIcon from '../../../../assets/reviews/review.svg';
import globalIcon from '../../../../assets/reviews/global.svg';

import { useStyles } from "./styles";

export const Review = ({ avatar, name, review }: any) => {
    const {
        wrapper, typ, header, customerImg, typ2,
        hintWrapper, contentWrapper, personIcon,
        customReviewRecommended, comment
    } = useStyles();

    return (
        <div className={wrapper}>
            <div className={header}>
                <img className={customerImg} src={avatar} alt='customer' />
                <div className={contentWrapper}>
                    <p className={typ}>
                        {name}
                        <img className={personIcon} src={reviewIcon} alt="" />
                    </p>
                    <div className={customReviewRecommended}>
                        <img src={globalIcon} alt="" />
                        <Typography className={typ2} variant={TypographyVariants.body2}>
                            recommends<span className={hintWrapper}>Able</span>
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography className={comment} variant={TypographyVariants.body2}>
                { typeof review === 'function' ? review() : TextNormalizer(review, { localStorageMode: true })}
            </Typography>
        </div>
    )
}
