const generatorOptions = (key: string, value: any) =>  ({
    'body-age': [
        {
            generate: () => {
                const newKey = 'body-shared-age'
                const newValue = Number.parseInt(value) >= 20 ? `in their ${value}` : value
                localStorage.setItem(newKey, newValue)
            }
        }
    ],
    'body-gender': [
        {
            generate: () => {
                const newKey = 'habits-lifestyle-gender'
                // @ts-ignore
                localStorage.setItem(newKey, {
                    'Male': 'Men',
                    'Female': 'Women'
                }[value])
            }
        }
    ],
    'body-goal': [
        {
            generate: () => {
                const newKey = 'habits-lifeevents-goal'
                // @ts-ignore
                localStorage.setItem(newKey, {
                    'gain muscle': 'muscle loss',
                    'lose weight': 'weight gain',
                    'get fit': 'being unfit'
                }[value])
            }
        },
        {
            generate: () => {
                const newKey = 'habits-sleep-goal'
                // @ts-ignore
                localStorage.setItem(newKey, {
                    'gain muscle': 'muscle \n gain',
                    'lose weigh': 'weight \n loss',
                    'get fit': 'fitness'
                }[value])
            }
        },
        {
            generate: () => {
                const newKey = 'habits-ligestyle-vp'
                const goal = localStorage.getItem('body-goal')
                // @ts-ignore
                if (goal === 'get fit') {
                    localStorage.setItem(newKey, 'get fit')
                }
            }
        }
    ],
    'body-idealweight': [
        {
            generate: () => {
                const newKey = 'habits-ligestyle-vp'
                const goal = localStorage.getItem('body-goal')
                const newValue = `reach an ideal weight between ${parseInt(value) - 10} and ${parseInt(value) + 10}`
                // @ts-ignore
                if (['gain muscle', 'lose weight'].includes(goal)) {
                    localStorage.setItem(newKey, newValue)
                } else {
                    localStorage.setItem(newKey, 'get fit')
                }
            }
        }
    ]
}[key])

export const customKeysGenerator = (key: string, value: any) => {
    generatorOptions(key, value)?.map(item => item.generate())
}