import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    text: {
       fontSize: 16,
       fontFamily: 'SF Pro Text',
       fill: '#232323',
       fontWeight: 600,
    },
    labelClass: {
        opacity: 0,
        transition: 'opacity 1.5s',
    },
    background: {
        backgroundColor: '#fff',
        height: 22,
        width: 50,
        top: -20,
    },
    active: {
        opacity: 1
    }
});
