import {useContext, useState} from "react";
import { useHistory } from "react-router-dom";
import {localStorageService, useLocalStorage} from '@ruby-labs/ruby-web-common';

import { BaseContext } from "../../../helpers/context/base.context";
import { getSignUpData } from "../../../utils/sign_up_data_getter";
import { SignUpApiProvider } from "../../../api/sign_up.api";
import { fbqParameters } from "../../../utils/facebook_params";

const variants: { [key: string]: string} = {
    "1_week_19_1-USD-Weekly": "1",
    "1_week_19_5-USD-Weekly": "5",
    "1_week_19_9-USD-Weekly": "9",
    "1_week_19_13-USD-Weekly": "13.21",
}


export const usePayment = () => {
    const history = useHistory();
    const { analytic } = useContext(BaseContext);
    const [error, setError] = useState('');
    const [cardError, setCardError] = useState('');

    const { pageValue: planCode } = useLocalStorage({ key: 'planCode', defaultValue: '' });
    const { pageValue: country } = useLocalStorage({ key: 'utm_country', defaultValue: '' });
    const { pageValue: email } = useLocalStorage({ key: 'email', defaultValue: '' });

    const onSubmitPayPal = ({ nonce: braintreeToken, details: billingContact }: any) => {
        const { shippingAddress, firstName, lastName } = billingContact;
        const data = getSignUpData({
            billingAddress: {
                country: shippingAddress?.countryCode,
                postalCode: shippingAddress?.postalCode,
                stateOrProvince: shippingAddress?.state,
                line1: shippingAddress?.line1,
                city: shippingAddress?.city,
            },
            planCode,
            braintreeToken,
            advertisingID: localStorage.getItem('userID'),
            merchantAccount:"Able_UK",
            paymentMethod: {
                type: 'paypal_express_checkout',
            },
            name: `${firstName} ${lastName}`,
            kountSessionID: localStorage.getItem('kount_session_id'),
            email,
        });

        return SignUpApiProvider.signUp(data)
            .then(async () => {
                analytic?.event('WinBackWebCheckoutStartClicked', {
                    value: variants[planCode as string] ,
                    source: "PayPal",
                    status: '200'
                })
                try {
                    analytic?.event('WinBackWebSubscriptionPurchased',
                        { PlanName: planCode, Source: 'PayPal' }
                    )
                    // @ts-ignore
                    console.log(window.dataLayer, 'work');
                    pushDataLayerGtag();

                    sendFBQParameters();
                    // @ts-ignore
                    window.gtag('event', 'Purchase');
                } catch (e) {
                } finally {
                    history.push('/congrats');
                }

            })
            .catch((e) => {
                console.log(e?.response)
                analytic?.event('WinBackWebCheckoutStartClicked', {
                    value: variants[planCode as string],
                    source: "PayPal",
                    status: e?.response?.status + ' ' + e?.response?.data.error
                })
                setError(e?.response?.data?.error);
                setCardError('')
            })
    }

    const startTrial = ({ token, name, postalCode }: any) => {
        const data = getSignUpData({
            name,
            billingAddress: { country, postalCode },
            planCode,
            advertisingID: localStorage.getItem('userID'),
            merchantAccount:"Able_UK",
            checkoutToken: token,
            kountSessionID: localStorage.getItem('kount_session_id')
        });

        return SignUpApiProvider.signUp(data)
            .then(async () => {
                analytic?.event('WinBackWebCheckoutStartClicked', {
                    value: variants[planCode as string] ,
                    source: "Credit Card",
                    status: '200'
                })
                try {
                    analytic?.event('WinBackWebSubscriptionPurchased',
                        { PlanName: planCode, Source: 'Credit Card' }
                    )
                    pushDataLayerGtag();

                    sendFBQParameters();
                    // @ts-ignore
                    window.gtag('event', 'Purchase');
                } catch (e) {
                } finally {
                    history.push('/congrats')
                }
            })
            .catch((e) => {
                console.log(e?.response)
                analytic?.event('WinBackWebCheckoutStartClicked', {
                    value: variants[planCode as string],
                    source: "Credit Card",
                    status: e?.response?.status + ' ' + e?.response?.data.error
                })
                setError('');
                setCardError(e?.response?.data?.error);
            })
            .finally(() => {})
    }

    const onSubmitApplePay = ({ event, price }: any) => {
        const tokenObj = event.token;

        const data = getSignUpData({
            billingAddress: {
                stateOrProvince: tokenObj.card?.address_state || '',
                country: tokenObj.card?.address_country || '',
                postalCode: tokenObj.card?.address_zip || '',
                city: tokenObj.card?.address_city || '',
                line1: tokenObj.card?.address_line1 || '',
                line2: tokenObj.card?.address_line2 || '',
            },
            planCode,
            amount: price,
            advertisingID: localStorage.getItem('userID'),
            merchantAccount:"Able_UK",
            applePayStripeToken: tokenObj.id,
            name: tokenObj.card?.name,
            stripePublicKey: localStorage.getItem('stripePublishableKey'),
            kountSessionID: localStorage.getItem('kount_session_id'),
            currency: 'USD'
        }, true);

        return SignUpApiProvider.signUp(data)
            .then(() => {
                try {
                    analytic?.event('WinBackWebCheckoutStartClicked', {
                        value: variants[planCode as string] ,
                        source: "Apple Pay",
                        status: '200'
                    })
                    analytic?.event('WinBackWebSubscriptionPurchased',
                        { PlanName: planCode, Source: 'Apple Pay' }
                    )
                    // @ts-ignore
                    console.log(window.dataLayer, 'work');
                    pushDataLayerGtag();

                    sendFBQParameters();
                    // @ts-ignore
                    if(window.gtag) {
                        // @ts-ignore
                        window.gtag('event', 'Purchase');
                    }
                } catch(e) {
                } finally {
                    history.push('/congrats');
                }
                event.complete('success');
            })
            .catch((e) => {
                console.log(e?.response)
                event.complete('fail');
                analytic?.event('WinBackWebCheckoutStartClicked', {
                    value: variants[planCode as string],
                    source: "Apple Pay",
                    status: e?.response?.status + ' ' + e?.response?.data?.error
                })
                setError(e?.response?.data?.error);
                setCardError('');
            })
    }

    const submitGooglePay = ({ event }: any) => {
        const tokenObj = event.token;

        const data = getSignUpData({
            billingAddress: {
                stateOrProvince: tokenObj.card?.address_state || '',
                country: tokenObj.card?.address_country || '',
                postalCode: tokenObj.card?.address_zip || '',
                city: tokenObj.card?.address_city || '',
                line1: tokenObj.card?.address_line1 || '',
                line2: tokenObj.card?.address_line2 || '',
            },
            planCode,
            advertisingID: localStorage.getItem('userID'),
            merchantAccount:"Able_UK",
            stripeToken: tokenObj.id,
            name: tokenObj.card?.name,
            stripePublicKey: localStorage.getItem('stripePublishableKey'),
            kountSessionID: localStorage.getItem('kount_session_id'),
            currency: 'USD'
        }, true);

        return SignUpApiProvider.signUp(data)
            .then(() => {
                try {
                    analytic?.event('WinBackWebCheckoutStartClicked', {
                        value: variants[planCode as string] ,
                        source: "Google Pay",
                        status: '200'
                    })
                    analytic?.event('WinBackWebSubscriptionPurchased',
                        { PlanName: planCode, Source: 'Google Pay' }
                    )
                    pushDataLayerGtag();

                    sendFBQParameters();
                    // @ts-ignore
                    if(window.gtag) {
                        // @ts-ignore
                        window.gtag('event', 'Purchase');
                    }
                } catch(e) {

                } finally {
                    history.push('/congrats');
                }
                event.complete('success');
            })
            .catch((e) => {
                event.complete('fail');
                console.log(e?.response)
                console.log('inside catch')
                analytic?.event('WinBackWebCheckoutStartClicked', {
                    value: variants[planCode as string],
                    source: "Google Pay",
                    status: e?.response?.status + ' ' + e?.response?.data?.error
                })
                setError(e?.response?.data?.error);
                setCardError('');
            })
    };

    const submitter = {
        applePay: onSubmitApplePay,
        googlePay: submitGooglePay,
        card: startTrial,
        payPal: onSubmitPayPal,
    };

    const handleSubmit = ({ type, ...props }: any) => {
        // @ts-ignore
        return submitter[type](props)
    };

    const handleClickAmazonButton = () => {
        localStorageService.setItem('amazon_initialized', true);
        history.push('/amazon-payment');
    };

    const modalCheckoutWindowTriggered = (state: boolean) => {
        if (state) {
            analytic?.event('WinBackSubscriptionUseCreditcardClicked');
        }
    };

    const pushDataLayerGtag = () => {
        // @ts-ignore
        window.dataLayer.push({'event':'Purchase', 'conversionValue':100});
        document.querySelectorAll('[height="1"]').forEach(el => {
            (el as HTMLElement).style.display = 'none';
        })
    }

    const sendFBQParameters = () => {
        const param = fbqParameters(localStorage.getItem('body-name') as string, '', '')
        window.fbq('trackCustom', 'Purchase', param);
    }

    return {
        error,
        cardError,
        handleSubmit,
        handleClickAmazonButton,
        modalCheckoutWindowTriggered,
        // optimizelyInfo
    }
}