import React from 'react';

import { ButtonFactory } from '../components/button_factory';
import { TypographiesFactory } from '../components/typographies_factory';
import { ThumbnailFactory } from '../components/thumbnails_factory';

export const componentConfigFile = {
  // @ts-ignore
  button: (props: any, path: string) => <ButtonFactory {...props} path={path} />,
  typographies: (props: any) => <TypographiesFactory {...props} />,
  additionalTypographies: (props: any) => <TypographiesFactory {...props} />,
  thumbnails: (props: any) => <ThumbnailFactory {...props} />,
}
