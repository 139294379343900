import React from 'react';
import ReactDOM from 'react-dom';
import * as queryString from 'querystring';

import './index.css';
import Index from './components/app';
import reportWebVitals from './reportWebVitals';

const availableRoutes = ['/update_payment'];

async function PrepareChimp () {
    const { userID } = queryString.parse(window.location.search);

    if (userID) localStorage.setItem('userID', userID as string)
    if (availableRoutes.includes(window.location.pathname)) {
        localStorage.setItem('available_routes', JSON.stringify([window.location.pathname]));
    }
}

PrepareChimp().finally(() => {
    ReactDOM.render(
        <React.StrictMode>
            <Index />
        </React.StrictMode>,
        document.getElementById('root')
    );
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
