import { createUseStyles } from "react-jss";

const commonLabelStyles = {
    fontSize: 16,
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
    display: 'flex',
    alignItems: 'center',
    height: 36,
    paddingLeft: 31,
}

export const useStyles = createUseStyles({
    wrapper: {
        borderRadius: 12,
        maxWidth: 343,
        margin: '0 16px',
        background: '#ffffff',
        padding: '24px 16px',
        width: '100%'
    },
    title: {
        lineHeight: '28px',
        fontWeight: 600,
        textAlign: 'center',
        maxWidth: 250,
        margin: '0 auto 8px',
        letterSpacing: '0.7px',
        color: '#202B47'
    },
    subtitle: {
        fontWeight: 400,
        textAlign: 'center',
        fontSize: 16,
        lineHeight: '24px',
        margin: '0 auto 32px',
        maxWidth: 305,
        color: '#202B47',
        '& span': {
            fontWeight: 600
        }
    },
    finalText: {
        maxWidth: 'auto',
        textAlign: 'left',
        margin: '0 0 32px',
        color: '#121620',
        '& span': {
            color: '#4B46EC',
            fontWeight: 600
        }
    },
    textWrapper: {
        minWidth: 107,
        height: 60,
        background: '#FFD702',
        borderRadius: 12,
        position: 'relative',
        padding: 12,
        boxSizing: 'border-box',
        marginBottom: 12
    },
    triangle: {
        position: 'absolute',
        bottom: -9,
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    labelWrapper: {
        minWidth: 107,
        maxWidth: 107,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 14
    },
    gainWrapper: {
      maxWidth: 127
    },
    imageContent: {
        paddingTop: 72,
        position: 'relative',
        paddingRight: 98,
        marginBottom: 32
    },
    planLabel: {
        ...commonLabelStyles,
        background: '#FE8F20',
        color: '#ffffff',
        marginBottom: 12,
        fontWeight: 600
    },
    ableLabel: {
        ...commonLabelStyles,
        color: '#4B46EC',
        border: '1px solid #4B46EC',
        background: '#F0F0FF',
        width: 106,
        boxSizing: 'border-box',
        fontWeight: 600
    },
    continueButton: {
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        width: '100%',
        '& > p': {
            fontWeight: 600
        }
    }
});
