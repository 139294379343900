import { useEffect, useState } from "react";

import { customKeysGenerator } from '../services/custom_keys_generator.service';
import { IsJsonString } from "../../utils/json_validator";

const createUpdateStorageCustomEvent = (value: any, keyEvent: string) => new CustomEvent('onStoreUpdated', {
    detail: { value, keyEvent }
});

const getDefaultValue = (key: string, defaultValue: any) => {
    const data = localStorage.getItem(key)
    if (data) {
        if (IsJsonString(data)) {
            return JSON.parse(data)
        }else{
            return data
        }
    } else {
        return defaultValue
    }
}

type StorageParams = {
    key: string;
    defaultValue: any;
}

export const useLocalStorage = ({ key, defaultValue }: StorageParams) => {
    const [pageValue, setPageValue] = useState(getDefaultValue(key, defaultValue))

    useEffect(() => {
        window.addEventListener('onStoreUpdated', (e:any) => {
            if(key === e.detail.keyEvent) {
                setPageValue(e.detail.value)
            }
        })

        return () => {
            window.removeEventListener('onStoreUpdated', (e:any) => {
                if(key === e.detail.keyEvent) {
                    setPageValue(e.detail.value)
                }
            })
        }
    }, [key])

    useEffect(() => {
        if (typeof pageValue === 'object') {
            localStorage.setItem(key, JSON.stringify(pageValue) || '')
        } else {
            localStorage.setItem(key, pageValue || '')
        }
        customKeysGenerator(key, pageValue)
        window.dispatchEvent(createUpdateStorageCustomEvent(pageValue, key))
    }, [pageValue, key])

    return {pageValue, setPageValue}
}
