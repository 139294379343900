import {makeAutoObservable} from "mobx"
import {createContext} from "react";

import { PreloadImgInstance } from './preload_img_store';

class Generic {
    constructor() {
        makeAutoObservable(this)
    }

    data: any = {}
    preloadedData: {[key: string]: any} = {}
    progress: {} = {}
    pageDataNotExist = false

    getLazyGenericData = async (path: string) => {
        if (this.preloadedData[path]) {
            this.data = this.preloadedData[path];

            if (this.preloadedData[path]["progress"]) {
                this.progress = this.preloadedData[path]["progress"];
            }
        }
        try {
            // const res = await import(`../page_configs/${path}.json`);
            //
            // this.data = res?.default;
            //
            // if (res?.default["progress"]) {
            //     this.progress = res?.default["progress"];
            // }

        } catch (e) {
            this.pageDataNotExist = true
        }
    }

    startPreloadPage = async (path: string) => {
        if (this.preloadedData[path]) return;
        try {
            const res = await import(`../page_configs${path}.json`);
            await PreloadImgInstance.startPreloadImage(res?.default, path);
            this.preloadedData[path] = res?.default;
        } catch (e) { }
    }

    clearPageData = () => {
        this.data = {}
    }
}

export const GenericInstance = new Generic();
export const GenericInstanceCTX = createContext(GenericInstance);

