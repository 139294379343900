import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
      padding: 0
    },
    header: {
        fontSize: '14px',
        lineHeight: '18px',
        padding: '10px',
        background: '#E0E3EB',
        display: 'flex',
        flex: 1,
        fontWeight: 600,
        fontFamily: 'SF Pro Text',
    }
});