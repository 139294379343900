import React, { useContext } from 'react';
import {useHistory} from "react-router-dom";

import { HeaderBlock } from "./header_block";
import { InfoBlock } from "./info_block";
import { HighLightList } from "./high_light_list";
import { CustomerReviews } from "./customer_reviews";
import { ContinueButton } from "./continue_button";
import { HeaderLogo } from "./header_logo";

import { BaseContext } from "../../helpers/context/base.context";
import { useStyles } from "./styles";

export const Subscribe = () => {
    const { analytic } = useContext(BaseContext);
    const { subscribeWrapper, content } = useStyles();
    const history = useHistory()

    const hideFirstPart = () => {
        analytic?.event('WinBackWebCheckoutContinueClicked');
        history.push('/payment')
    }

    return (
        <div className={subscribeWrapper}>
            <HeaderBlock />
            <HeaderLogo />
            <div className={content}>
                <InfoBlock />
                <HighLightList />
                <CustomerReviews />
            </div>
            <ContinueButton onClick={hideFirstPart} />
        </div>
    );
};
