import React from "react";
import {useLocation} from "react-router-dom";

import { Layout } from "../layout";

import { useStyles } from "./styles";
import {UserHeader} from "../user_header";
import {ROUTES_WITH_HEADER, ROUTES_WITHOUT_MARGINS} from "../../constants/layout";
import {useAccessPageChecking} from "../../helpers/hooks/access_page_checking.hook";

type MainContentTypes = {
    children: React.ReactChild
}

export const MainContent = ({ children }: MainContentTypes) => {
    const { routerAvailable } = useAccessPageChecking()
    const { mainContent } = useStyles();
    const {pathname} = useLocation()

    const isHeaderVisible = ROUTES_WITH_HEADER.includes(pathname)
    const isWrapperMainContentActive = !ROUTES_WITHOUT_MARGINS.includes(pathname)

    if(!routerAvailable) return null

    return (
        <>
            {isHeaderVisible ? <UserHeader /> : null}
            {
                isWrapperMainContentActive ? (
                    <div className={mainContent}>
                        <Layout>
                            {children}
                        </Layout>
                    </div>
                ) : children
            }
        </>
    )
}
