import {useState, useEffect, useContext} from "react";
import { useHistory } from 'react-router-dom';

import { localStorageService } from "@ruby-labs/ruby-web-common";

import { BaseContext } from "../../helpers/context/base.context";
import { CouponsApiProvider } from "../../api/coupons.api";

const CURRENCY = 'USD';

type UseAddonProps = {
    prefix: string,
    prefixDiscount: string
}

export const useAddon = ({ prefix, prefixDiscount }: UseAddonProps) => {
    const history = useHistory();
    const isDiscount = window.location.pathname === '/addon-discount';
    const { analytic } = useContext(BaseContext);
    const [open, setOpen] = useState(isDiscount);
    const [loading, setLoading] = useState(false);
    const email = localStorageService.getItem('email') || '';

    const onSubmit = () => {
        const longPrefix = isDiscount ? `_discount${prefixDiscount}` : prefix;
        const addon = `custom_meal_self_care_pr_bundle${longPrefix}`;
        const charge = `${addon}-${CURRENCY}`;

        setLoading(true)

        CouponsApiProvider.upgradeAddon({ charge, email })
            .then(() => {
                const destinationPath = '/wait';
                const eventName = isDiscount ? 'WinBackWebAddonsDiscountAddClicked' : 'WinBackWebAddonsAddClicked';

                analytic?.event(eventName, { value: addon, status: 200 });

                localStorageService.setItem('skipped_addon', '');
                history.push(destinationPath);
            })
            .finally(() => setLoading(false));
    }

    const skipAddon = () => {
        analytic?.event('WinBackWebAddonsSkipTopClicked');

        localStorageService.setItem('skipped_addon', 'skipped');
        setOpen(true);
        history.push('/addon-discount');
    }

    const skipAddonDiscount = () => {
        analytic?.event('WinBackWebAddonsDiscountSkipTopClicked');

        history.push('/access');
    }

    const handleClose = () => {
        analytic?.event('WinBackWebAblePlanBenefitsContinueClicked');
        setOpen(false);
    }

    const skip = isDiscount ? skipAddonDiscount : skipAddon;

    useEffect(() => {
        const eventName = isDiscount ? 'WinBackWebAddonsDiscountViewed' : 'WinBackWebAddonsViewed';

        analytic?.event(eventName);
    } , []);

    return {
        loading,
        isDiscount,
        handleClose,
        onSubmit,
        skip,
        open,
    }
}
