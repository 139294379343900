export const themeV2 = {
    primaryButton: ({ palette } : any) => ({
        width: '100%',
        height: 48,
        borderRadius: '8px',
        background: palette.primary,
        color: palette.light,
        transition: 'background .3s, color .3s, box-shadow 2s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        '& p': {
            fontWeight: 600,
            color: 'white',
            lineHeight: '22px!important'
        },
        '&:hover': {
            background: palette.primary_dark
        },
        '&:active': {
            boxShadow: palette.boxShadow
        }
    }),
    checkButton: ({ palette }: any) => ({
        display: 'flex',
        alignItems: 'center',
        height: 56,
        boxSizing: 'border-box',
        '&$selectedClass': {
            background: palette.light,
            border: `1px solid ${palette.primary}`,
            color: palette.black,

            '& $checkWrapper': {
                background: palette.primary
            }
        },
        '& $checkWrapper': {
            order: 1,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 24,
            width: 24,
            height: 24,
            marginRight: 12,
            background: palette.white
        },
        '& $checkIconCl': {
            width: 12,
            '& path': {
                color: palette.white
            }
        },
        '& $childrenStyle': {
            order: 2,
            paddingRight: '32px',
            textAlign: 'left',
            width: '100%'
        },
        '& $centeredChildren': {
            padding: 0
        }
    }),
    typography: {
        title: {
            fontSize: 18,
            lineHeight: '22px',
            color: '#202B47',
            fontWeight: 600
        }
    }
}
