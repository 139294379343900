import React, {useContext} from 'react';
import logoIcon from '../../assets/svg/access_logo.svg'
import {PrimaryButton, Typography, TypographyVariants, useLocalStorage} from "@ruby-labs/ruby-web-common";

import accessLogin from '../../assets/img/accessLogin.png';
import accessContinue from '../../assets/img/accessContinue.png';
import {BaseContext} from "../../helpers/context/base.context";
import {useStyles} from "./styles";

export const Access = ()  => {
    const {
        page, logo, title, list,
        listItem, boldText, textBreak,
        continueImage, emailContinue, loginImage,
        downloadButton, listItemContent,
    } = useStyles()
    const { pageValue: email } = useLocalStorage({key: 'email', defaultValue: ''})

    const { analytic } = useContext(BaseContext);
    const linkDownload = `https://able-app.onelink.me/3FAG?deep_link_value=ableapp://email_login?email=${email}`

    const onSubmit = () => {
        analytic?.event(`WinBackWebDownloadButtonClicked`);
        window.open(linkDownload, '_blank');
    }

    return (
        <div className={page}>
            <div className={logo}>
                <img src={logoIcon} alt="able logo"/>
            </div>
            <Typography
                variant={TypographyVariants.title}
                className={title}
            >
                Simply follow these steps to <br className={textBreak}/> access your account
            </Typography>
            <ul className={list}>
                <li className={listItem}>
                    <div className={listItemContent}>1</div>
                    <div>Download Able app</div>
                </li>
                <li className={listItem}>
                    <div className={listItemContent}>2</div>
                    <div>Open the app and tap “<span className={boldText}>Log In</span>“ (see
                        <br className={textBreak}/> image below).</div>
                </li>
                <img
                    className={loginImage}
                    src={accessLogin}
                    alt="access login"
                />
                <br className={textBreak}/>
                <br className={textBreak}/>
                <div className={emailContinue}>Then tap “<span className={boldText}>Continue with email</span>”</div>
                <br className={textBreak}/>
                <img
                    className={continueImage}
                    src={accessContinue}
                    alt="access continue"
                />
                <li className={listItem}>
                    <div className={listItemContent}>3</div>
                    <div>Enter your email
                        <br className={textBreak}/> "<span className={boldText}>{email}</span>" and
                        <br className={textBreak}/> follow the instructions to create your
                        <br className={textBreak}/> password.</div>
                </li>
            </ul>
            <PrimaryButton
                label='Download Able'
                className={downloadButton}
                onClick={onSubmit}
            />
        </div>
    )
};
