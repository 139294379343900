import React, {useContext} from "react";
import { useStyles } from "./styles";
import { observer } from "mobx-react-lite"
import { ProgressStepper } from "@ruby-labs/ruby-web-common";

import {GenericInstanceCTX} from "../../store/generic_store";

type LayoutTypes = {
    children: React.ReactChild,
    isProgressBarActive?: boolean
}

export const Layout = observer(({ children, isProgressBarActive }: LayoutTypes) => {
    const { content, header } = useStyles({isProgressBarActive});
    const { progress }: any = useContext(GenericInstanceCTX)

    return (
        <>
            {
                isProgressBarActive ? (
                    <div className={header}>
                        <ProgressStepper
                            colors={[
                                '#4B46EC',
                                '#FE8F20',
                                '#04A777'
                            ]}
                            percents={parseInt(progress['progress-percent'])}
                            steps={3}
                            title={progress?.label}
                        />
                    </div>
                ) : null
            }
            <div className={content}>
                {children}
            </div>
        </>
    )
})
