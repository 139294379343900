import {sha256} from './hash';

export const fbqParameters = async (fname: string, lname: string, zipcode: string) => {
    const promises = [
        sha256(localStorage.getItem('email') || ''),
        sha256((localStorage.getItem('body-gender') || '').charAt(0).toLowerCase()),
        sha256(localStorage.getItem('utm_country') || ''),
        sha256(fname),
        sha256(lname),
        sha256(zipcode)
    ]
    const res = await Promise.all(promises)
    const ret = {
        external_id: await sha256(localStorage.getItem('userID') || ''),
        subscription_id: localStorage.getItem('userID') || '',
        currency: "USD",
        value: 100,
        client_user_agent: navigator.userAgent,
        em: res[0],
        ge: res[1],
        country: res[2],
        fn: res[3],
        ln: res[4],
        zp: res[5]
    }
    return ret
}