import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    listItem: {
        display: 'flex',
        marginBottom: 16,

        '& img': {
           height: 22,
            marginTop: 1
        },

        '&:last-child': {
            marginBottom: 0
        }
    },
    highLightList: {
        padding: '0 0px 28px 0px',
    },
    listItemText: {
        textAlign: 'left',
        marginLeft: 6,
        fontSize: 14,
        lineHeight: '18px'
    },
    title: {
        marginBottom: 12,
        fontWeight: 600,
        lineHeight: '22px',
        paddingRight: '15px'
    },
    chart: {
        marginBottom: 24
    }
});
