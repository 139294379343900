import React, { memo, useEffect, useState } from 'react';

import { useStyles } from "./styles";

export const CustomizedLabel = memo(({ x, y, stroke, value, label, index: currentLabel, labelsLength }: any) => {
    const { text, active, labelClass, background } = useStyles();
    const [activeLabel, setActiveLabel] = useState(false);

    useEffect(() => {
        setActiveLabel(true);
    }, [])

    // hide first and last label on graph
    if(!currentLabel || labelsLength - 1 === currentLabel) return null;

    const className = `${labelClass} ${activeLabel ? active : ''}`;

    return (
        <>
            <g>
                <rect
                    className={background}
                    x={x -25}
                    y={y -38}
                    dy={-4}
                    fill={'#ffffff'}
                />
            </g>
            <g className={className}>
                <text
                    x={x}
                    y={y - 17}
                    dy={-4}
                    className={text}
                    fontSize={10}
                    textAnchor="middle"
                    fill={stroke}
                >
                    {value}{label}
                </text>
            </g>
        </>
    )
})
