import React from "react";

import { Typography, TypographyVariants } from "@ruby-labs/ruby-web-common";

import discountIcon from '../../../assets/svg/discount_icon.svg';
import { useStyles } from "./styles";

export const DiscountBlock = () => {
    const { wrapper, img, text } = useStyles();

    return (
        <div className={wrapper}>
            <img className={img} src={discountIcon} alt="" />
            <Typography className={text} variant={TypographyVariants.h4}>
                Additional 25% off discount applied!
            </Typography>
        </div>
    );
}
