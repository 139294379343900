import React, {useContext, useState} from "react";
import { useHistory } from "react-router-dom";
import {
  PrimaryButton,
  SelectButton,
  Typography, TypographyVariants,
  useLocalStorage
} from "@ruby-labs/ruby-web-common";


import { BaseContext } from "../../helpers/context/base.context";
import { useStyles } from "./styles";

const variants = [
  { label: "$1", planCode: "1_week_19_1-USD-Weekly" },
  { label: "$5", planCode: "1_week_19_5-USD-Weekly" },
  { label: "$9", planCode: "1_week_19_9-USD-Weekly" },
  { label: "$13.21", planCode: "1_week_19_13-USD-Weekly" },
];

export const TrialPay = () => {
  const history = useHistory();
  const { analytic } = useContext(BaseContext);

  const { pageValue, setPageValue } = useLocalStorage({
    key: "planCode",
    defaultValue: "",
  });
  const { setPageValue: setPlanCodeLabel } = useLocalStorage({
    key: "planCodeLabel",
    defaultValue: "",
  });
  // @ts-ignore
  const eValue = pageValue ? `${variants?.find(({ planCode }: any) => planCode === pageValue).label} price` : ''
  const [eventValue, setEventValue] = useState<string>(eValue)

  const {
    pageWrapper,
    page,
    firstText,
    secondText,
    thirdText,
    buttons,
    button,
    buttonDescription,
    simpleButton,
  } = useStyles();

  const onSubmit = () => {
    analytic?.event("WinBackWebTrialPayNextClicked", { value: eventValue?.split(' ')[0] });
    history.push("/subscribe");
  };

  return (
      <div className={pageWrapper}>
        <div className={page}>
          <Typography variant={TypographyVariants.body1} className={firstText}>
            We've helped millions of people to have happier lives and better
            relationships, and we want to help you too.
          </Typography>
          <Typography variant={TypographyVariants.body1} className={secondText}>
            Money shouldn’t stand in the way of finding a plan that finally works.
            So, choose an amount that you think is reasonable to try us out for one
            week.
          </Typography>
          <Typography variant={TypographyVariants.body1} className={thirdText}>
            It costs us $13.21 to compensate our Able employees for the trial, but
            please choose the amount you are comfortable with.
          </Typography>
          <div className={buttons}>
            {variants.map((data, i) => {
              return (
                  <div>
                    <SelectButton
                        label={data.label}
                        className={button}
                        selected={pageValue === data.planCode}
                        onClick={() => {
                          setPageValue(data.planCode);
                          setEventValue(`${data.label} price`)
                          setPlanCodeLabel(data.label.split("$")[1]);
                        }}
                    />
                    {i === 3 ? (
                        <Typography variant={TypographyVariants.label} className={buttonDescription}>
                          Most
                          <br />
                          popular
                          <br />
                          choice*
                        </Typography>
                    ) : (
                        ""
                    )}
                  </div>
              );
            })}
          </div>
          <PrimaryButton
              label="Next"
              className={simpleButton}
              disabled={!pageValue}
              onClick={onSubmit}
          />
        </div>
      </div>
  );
};
