import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '114px 32px',
    },
    progressBarClass: {
        width: '100%',
        marginBottom: 32
    },
    text: {
        marginTop: 35,
        textAlign: 'center',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.0008em',
        color: '#121620',
    },
    textBreak: {
        display: 'block',
    },
    '@media (max-width: 365px)' : {
        textBreak: {
            display: 'none',
        }
    },
});
