import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    infoBlock: {
        padding: '23px 0px 32px 0px'
    },
    title: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        marginBottom: 12
    },
    subtitle: {
        textAlign: 'left',
        paddingRight: 5,
        '& span': {
            fontWeight: 600,
            color: '#FE8F20'
        }
    }
});
