import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    pageWrapper: {
        paddingTop: '88px'
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 16px 39px 16px',
        textAlign: 'center',
    },
    firstText: {
        fontFamily: 'SF pro Text',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.0024em',
        color: '#202B47',
        marginTop: 0,
        marginBottom: '12px',
        textAlign: 'left',

    },
    secondText: {
        fontFamily: 'SF pro Text',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.0024em',
        color: '#202B47',
        marginTop: 0,
        marginBottom: '12px',
        textAlign: 'left',
    },
    thirdText: {
        fontFamily: 'SF pro Text',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.0024em',
        color: '#4B46EC',
        marginTop: 0,
        marginBottom: '24px',
        textAlign: 'left',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    button: {
        padding: '17px 10px',
        width: '72px',
        minWidth: '72px',
        height: '72px'
    },
    buttonDescription: {
        fontFamily: 'SF pro Text',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '9px',
        lineHeight: '10px',
        letterSpacing: '0.0008em',
        textTransform: 'uppercase',
        color: '#202B47',
        marginTop: '20px',
        marginBottom: 0,
        position: 'relative',
        '&::before': {
            top: '-16px',
            left: '34px',
            position: 'absolute',
            content: '""',
            width: '1px',
            background: '#202B47',
            height: '15px',
        }
    },
    simpleButton: {
        height: '48px',
        width: '100%',
        padding: '0 8px',
        fontSize: '16px',
        marginTop: '32px',
        letterSpacing: '1px',
    },
    '@media (max-width: 365px)' : {
        textBreak: {
            display: 'none',
        },
        buttons: {
            '& >div': {
                marginRight: '8px',
                width: '100%',
                '& >button': {
                    padding: '17px 0px',
                    width: '100%',
                    minWidth: '100%'
                },
                '& >p:before': {
                    left: '29px'
                }
            },
            '& >div:last-child': {
                marginRight: 0
            }
        },
    }
})