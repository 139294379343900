import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        background: '#FFE9D2',
        borderRadius: 8,
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        margin: '16px 0'
    },
    img: {
        marginRight: 16
    },
    text: {
        fontWeight: 500
    }
});
