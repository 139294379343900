import React, {useEffect} from 'react';
import {Paywall} from "@ruby-labs/ruby-web-common";

import { HeaderLogo } from "../header_logo";
import { PaymentInfo } from "./payment_info";

import { usePayment } from "./payment_hook";
import {usePaywallInfo} from "./paywall_info.hook";
import { useStyles } from "./styles";

export const Payment = () => {
    const { paymentWrapper, chargedText, checkoutTitle } = useStyles();
    const {
        stripeProp, countryCode, accessPaymentsKeys,
        price, loadedKeys
    } = usePaywallInfo();
    const {
        handleSubmit,
        handleClickAmazonButton,
        error,
        cardError,
        modalCheckoutWindowTriggered,
    } = usePayment();


    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    return (
        <>
            <HeaderLogo />
            <div className={paymentWrapper}>
                <PaymentInfo price={price} />
                <Paywall
                    checkoutModalTitleClass={checkoutTitle}
                    paypalButtonProps={{ height: 45, borderRadius: 10 }}
                    isUpdateForm={false}
                    loading={!loadedKeys}
                    error={error}
                    cardError={cardError}
                    countryCode={countryCode}
                    accessPaymentsKeys={accessPaymentsKeys}
                    stripeProp={stripeProp}
                    env={process.env.REACT_APP_ENV as string}
                    isAmazonActive={false}
                    isPaypalActive
                    isApplePayActive
                    isGooglePayActive
                    onSubmit={handleSubmit}
                    onAmazonButtonClicked={handleClickAmazonButton}
                    modalCheckoutWindowTriggered={modalCheckoutWindowTriggered}
                    formText={
                        <div className={chargedText}>
                            You will be charged only <span>{price}$ for your 7-day trial.</span> <br />
                            We'll <span>email you a reminder</span> before your trial period ends. Cancel anytime.
                        </div>
                    }
                />
            </div>
        </>
    );
}