import { createUseStyles } from "react-jss";

const commonStylePrice = {
    fontWeight: 400,
    textAlign: 'center'
}

const commonBorderStyles = {
    borderTop: '1px solid #E0E3EB',
    paddingTop: 12
}

export const useStyles = createUseStyles({
    wrapper: {
        borderRadius: 8,
        padding: 16,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)'
    },
    title: {
        marginBottom: 12,
        color: '#202B47',
        fontWeight: 700
    },
    text: {
        fontWeight: 400,
        color: '#202B47'
    },
    block: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12
    },
    blockImg: {
        marginRight: 16
    },
    oneTimePriceWrapper: {
        ...commonBorderStyles
    },
    oneTimePrice: {
        ...commonStylePrice,
        color: '#4B46EC',
        marginBottom: 4,
        fontSize: 16,
        lineHeight: '18.75px',
        fontWeight: 700
    },
    originalPriceClass: {
        ...commonStylePrice,
        color: '#202B47'
    },
    keepPlan: {
        ...commonStylePrice,
        ...commonBorderStyles,
        color: '#202B47',
        marginTop: 12,
        '& span': {
            fontWeight: 600
        }
    }
});
