import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import queryString from 'query-string';
import { OptimizelyProvider } from "@optimizely/react-sdk";

import { useLoadKountSessionScript } from "@ruby-labs/ruby-web-common";

import { MainContent } from "../main_content";
import {
    TrialPay, Congrats, Access,
    Addons, TrialSkip, Finisher,
    GenericPage, Subscribe, Payment,
    Wait
} from '../../pages';

import {CustomThemeProvider} from "../custom_theme_provider";
import { OPTIMIZELY_ATTRIBUTES } from "../../constants/base";
import {BaseContext} from "../../helpers/context/base.context";
import {analytics_service} from "../../helpers/services/analitics.service";
import {useCountryLocation} from "../../helpers/hooks/use_location.hook";
import {ScrollToTop} from "../scroll_to_top";
import {useLoadData} from "../../helpers/hooks/load_data.hook";

function Index() {
    const { planid, userID } = queryString.parse(window.location.search);
    const analytic = analytics_service();
    if (planid) {
        analytic.setAmplitudeUserId(planid || userID)
    }
    const { isPlanValid, subscribed } = useLoadData()
    const { country } = useCountryLocation();
    useLoadKountSessionScript();

    return (
        <OptimizelyProvider
            optimizely={analytic.optimizely}
            user={{
                id: analytic.userId,
                attributes: OPTIMIZELY_ATTRIBUTES,
            }}
        >
            <CustomThemeProvider>
                <BaseContext.Provider value={{ analytic, country, isPlanValid, subscribed }}>
                    <Router>
                        <ScrollToTop />
                        <Route exact path='/'>
                            <Finisher />
                        </Route>
                        <MainContent>
                            <Switch>
                                <Route path="/about">
                                    custom route
                                </Route>
                                <Route path='/trial-pay'>
                                    <TrialPay />
                                </Route>
                                <Route path='/congrats'>
                                    <Congrats />
                                </Route>
                                <Route path='/access'>
                                    <Access />
                                </Route>
                                <Route path="/addon-discount">
                                    <Addons />
                                </Route>
                                <Route path='/addons'>
                                    <Addons />
                                </Route>
                                <Route exact path='/trialskip'>
                                    <TrialSkip />
                                </Route>
                                <Route exact path="/subscribe">
                                    <Subscribe />
                                </Route>
                                <Route path="/update_payment">
                                    <Payment />
                                </Route>
                                <Route path="/wait">
                                    <Wait />
                                </Route>
                                <Route path="/payment">
                                    <Payment />
                                </Route>
                                <Route path="/:path">
                                    <GenericPage/>
                                </Route>
                            </Switch>
                        </MainContent>
                    </Router>
                </BaseContext.Provider>
            </CustomThemeProvider>
        </OptimizelyProvider>
    );
}

export default Index;
