import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    paymentHeader: {
        position: 'fixed',
        width: '100%',
        background: 'white',
        top: 0,
        left: 0,
        zIndex: 1
    },
    header: {
        textAlign: 'center',
        padding: '12px 26px',
        background: '#FFF0EB',
        height: 65,
        overflow: 'hidden',
        boxSizing: 'border-box',
        maxWidth: 428,
        margin: '0 auto',
        fontSize: '14px',
        lineHeight: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerContent: {
        maxWidth: 290,
        margin: '0 auto',
        fontSize: 14,
        lineHeight: '18px'
    },
    headerTitleBold: {
        fontWeight: 500
    },
    headerTitleColored: {
        color: '#36B992 !important',
        fontWeight: 600,
    }
})
