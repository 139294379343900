import React, {useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {PrimaryButton} from "@ruby-labs/ruby-web-common";

import {isDataInPageNotExist} from "../../../../utils/buttons_conditions";
import {BaseContext} from '../../../../helpers/context/base.context'
import {useLocalStorage} from "../../../../helpers/hooks/local_storage.hook";
import {GenericInstanceCTX} from "../../../../store/generic_store";
import {DestinationServiceInstance} from "../../../../helpers/services/destination.service";

export const ButtonBaseLogic = ({ className, destinationPath: destination, path, alwaysAble, event, ...props }: any) => {
    let history = useHistory();
    const {analytic} = useContext(BaseContext);
    const {pageValue} = useLocalStorage({ key: path, defaultValue: '' })
    const {pageValue: pageError} = useLocalStorage({ key: `${path}_error`, defaultValue: '' })

    const { startPreloadPage }: any = useContext(GenericInstanceCTX)
    const destinationPath = DestinationServiceInstance.parse({destination})

    useEffect(() => {
        startPreloadPage(destinationPath);
    },[startPreloadPage, destinationPath])

    const isButtonDisabled = alwaysAble ? false : (isDataInPageNotExist(pageValue) || pageError)

    const buttonClicked = () => {
        if (event) analytic?.event(event);
        history.push(destinationPath);
    }

    return (
        <div className={className}>
            <PrimaryButton
                onClick={buttonClicked}
                disabled={isButtonDisabled}
                {...props}
            />
        </div>
    );
}
