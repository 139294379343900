import React, {useEffect, useState} from 'react'
import {
    Area, ComposedChart, Line, XAxis, CartesianGrid,
    ResponsiveContainer, YAxis, LabelList
} from "recharts";
import { useLocalStorage } from '@ruby-labs/ruby-web-common';

import { CustomizedLabel } from "./customized_label";
import { CustomizedDot } from "./customized_dot";
import { BodyPlanCalculator } from "../../helpers/services/body_plan_calculator.service";

import { useStyles } from "./styles";

const WEIGHT_UNIT_SYSTEM = {
    'metric': 'kg',
    'imperial': 'lb'
};

const GOAL_VALUES = {
    'gainMuscle': 'gain muscle',
    'getFit': 'get fit',
    'loseWeight': 'lose weight'
}

export const WeightGraphic = ({ className }: { className?: string }) => {
    const { lineChart, chartWrapper, active } = useStyles();
    const [activeCharts, setActiveCharts] = useState(false);
    const [data, setData] = useState<Array<any>>([]);
    const { pageValue: bodyWeight }: any = useLocalStorage({ key: 'body-weight', defaultValue: '' });
    const { pageValue: bodyGoal }: any = useLocalStorage({ key: 'body-goal', defaultValue: '' });
    const { pageValue: bodyGoalWeight }: any = useLocalStorage({ key: 'body-idealweight', defaultValue: '' });
    const { pageValue: bodyUnit }: any = useLocalStorage({ key: 'unit', defaultValue: '' });

    //@ts-ignore
    const unit = WEIGHT_UNIT_SYSTEM[bodyUnit];
    //@ts-ignore
    const goal = GOAL_VALUES[bodyGoal];
    const weight = Number(bodyWeight);
    const goalWeight = Number(bodyGoalWeight);


    useEffect(() => {
        const calculatorProps = { weight, goal, goalWeight, unit };
        const planCalculator = new BodyPlanCalculator(calculatorProps);
        const graphicData = planCalculator.getData();
        setData(graphicData);
    }, [])

    //@ts-ignore
    const correctUnit = (goal === GOAL_VALUES.getFit || goalWeight === weight) ? '%' : unit;

    return (
        <div className={`${chartWrapper} ${className || ''}`}>
            <ResponsiveContainer>
                <ComposedChart
                    margin={{ right: -5, left: -65 }}
                    width={1000}
                    height={248}
                    data={data}
                    className={`${lineChart} ${activeCharts ? active : ''}`}
                >
                    <CartesianGrid
                        stroke="#C5C5D1"
                        horizontal={false}
                        strokeDasharray="3 3"
                    />
                    <defs>
                        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1" gradientTransform="rotate(16)">
                            <stop offset="41.21%" stopColor="#FEE2BF" stopOpacity={1}/>
                            <stop offset="72%" stopColor="white" stopOpacity={1}/>
                        </linearGradient>
                    </defs>
                    <Area
                        onAnimationEnd={() => setActiveCharts(true)}
                        type="monotone"
                        dataKey="fd"
                        stroke="#FE8F20"
                        fillOpacity={1}
                        fill="url(#gradient)"
                    />
                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        orientation="top"
                        dataKey="name"
                    />
                    <YAxis padding={{ top: 47 }} />
                    <Line
                        type="monotone"
                        dataKey="fd"
                        stroke="#FE8F20"
                        strokeWidth={2}
                        fill="#FE8F20"
                        r={5}
                        dot={<CustomizedDot dotsLength={data.length} />}
                        activeDot={{ r: 8 }}
                    >
                        <LabelList dataKey='uv' content={<CustomizedLabel labelsLength={data.length} label={correctUnit} />} />
                    </Line>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}