import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    paymentWrapper: {
        padding: '0 16px 48px'
    },
    chargedText: {
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '18px',
        margin: '0 0 16px 0',
        color: '#121620',
        '&>span': {
            fontWeight: 500
        }
    },
    checkoutTitle: {
        color: '#000000'
    }
});
