import React, {useEffect} from "react";
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from "@ruby-labs/ruby-web-common";

import { useStyles } from "./styles";

export const UserHeader = () => {
    const { header, headerContent, headerAvatar, headerEmail } = useStyles();
    const { pageValue: email } = useLocalStorage({
        key: "email",
        defaultValue: "",
    });
    const firstLetter = email ? email.split("")[0].toUpperCase() : "";

    return (
        <>
            <header className={header}>
                <div className={header}>
                    <div className={headerContent}>
                        <div className={headerAvatar}>{firstLetter}</div>
                        <div className={headerEmail}>{email}</div>
                    </div>
                </div>
            </header>
        </>
    );
};
