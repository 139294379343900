import React from 'react';
import {Typography, TypographyVariants} from "@ruby-labs/ruby-web-common";
import { useStyles } from "./styles";

type PaymentInfoProps = {
    price: string | number
}

export const PaymentInfo = ({ price }: PaymentInfoProps) => {
    const {
        specialOffer, title, totalPriceCount,
        codeHint, firstPrice, secondPrice, costTwoWeekPrice,costTwoWeeksText,
        subtitle, totalPrice, totalPriceClass, saveText, chargedText
    } = useStyles();

    return (
        <>
            <Typography variant={TypographyVariants.body2} className={specialOffer}>
                Special Offer
            </Typography>
            <Typography className={title}  variant={TypographyVariants.h2}>
                Start your 7-day trial
            </Typography>
            <Typography className={subtitle}  variant={TypographyVariants.h3}>
                No commitment. Cancel anytime.
            </Typography>
            <div className={totalPrice}>
                <Typography className={totalPriceClass} variant={TypographyVariants.body2}>Total today</Typography>
                <Typography className={totalPriceCount} variant={TypographyVariants.body2}>${price}</Typography>
            </div>
            <Typography className={codeHint} variant={TypographyVariants.body2}>
                Code ABLE22 Applied!
            </Typography>
            <div className={costTwoWeekPrice}>
                <Typography variant={TypographyVariants.body2} className={costTwoWeeksText}>
                    Your cost per week after trial
                </Typography>
                <Typography className={firstPrice} variant={TypographyVariants.body2}>
                    <s>$29</s>
                </Typography>
                <Typography className={secondPrice} variant={TypographyVariants.body2}>
                    $19
                </Typography>
            </div>
            <Typography className={saveText} variant={TypographyVariants.body2}>
                Save $10 every week
            </Typography>
            <div className={chargedText}>
                You will be charged only <span>{price}$ for your 7-day trial.</span> <br />
                We'll <span>email you a reminder</span> before your trial period ends. Cancel anytime.
            </div>
        </>
    );
};
