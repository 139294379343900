import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: "56px 24px 0"
    },
    img: {
        marginTop: 62
    },
    title: {
        lineHeight: '28px',
        fontWeight: 700,
        textAlign: 'center',
        maxWidth: 250,
        margin: '32px auto 4px',
        letterSpacing: '0.7px',
        color: '#202B47'
    },
    text: {
        fontWeight: 400,
        maxWidth: 305,
        textAlign: 'center',
        margin: '12px 0 32px',
        color: '#202B47',
        '& span': {
            fontWeight: 600
        }
    },
    button: {
        height: 48,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
});
