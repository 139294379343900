import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    title: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '26px',
        textAlign: "center",
        height: '60px',
    }
});
