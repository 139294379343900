import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    content: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',

        '& button': {
            marginBottom: 10
        },
        '& button:last-child': {
            marginBottom: 0
        },
        '&>div': {
            width: '100%',
            marginBottom: 10
        },
        '&>div:last-child': {
            marginBottom: 0
        }
    },
    baseButton: {
        marginTop: 24
    }
})
