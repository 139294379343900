import React from 'react';
import shortid from 'shortid';
import parser from 'html-react-parser';
import { Typography, TextNormalizer } from '@ruby-labs/ruby-web-common';


export const TypographiesFactory = ({ order, ...typographiesConfig }: any) => {
    const data = typeof typographiesConfig === 'object' ? Object.values(typographiesConfig) : typographiesConfig

    return data?.map(({ variant, text, ...typography}: any) => (
        <Typography
            key={shortid.generate()}
            {...typography}
            variant={variant}
        >
            {parser(TextNormalizer(text, {
                variables: {},
                brChanger:true,
                localStorageMode: true,
                tagWrappers: [['*', 'p'], ['$', 'div']]
            }))}
        </Typography>
    ))
}
