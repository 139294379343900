import jsep from "jsep";
import {TextNormalizer} from "@ruby-labs/ruby-web-common";

import {evaluateExpressionTree} from '../../utils/evaluate_expression_tree';

class DestinationService {
    private parser: (params: string) => string = ((params) => params);

    private isExpressionHasLogicalOperators = (expression: string): boolean => {
        return /AND|OR|and|or|XOR|&&|<=|>=|<|>|!=|==|&|OR*|!|\|{1,2}/gi.test(expression)
    }

    constructor(parser?: (text: string) => any) {
        if (parser) this.parser = parser;
    }

    public parse = ({ destination }: {destination: string}): string => {
        const parsedString = this.parser(destination);
        if(!this.isExpressionHasLogicalOperators(parsedString)) return destination
        return evaluateExpressionTree(jsep(parsedString)) || destination;
    }

}

export const DestinationServiceInstance = new DestinationService(
    (text: string): string => {
        return TextNormalizer(text, {
            localStorageMode: true,
            tagWrappers: [['*', 'p'], ['$', 'div']]
        })
    }
);
