import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    content: {
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '0 32px',
    },
    title: {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.0024em',
        color: '#202B47',
        marginTop: '0',
        marginBottom: '12px',
    },
    text: {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.0024em',
        color: '#202B47',
        marginTop: '0',
    },
    textBreak: {
        display: 'block',
    },
    '@media (max-width: 365px)' : {
        textBreak: {
            display: 'none',
        }
    },
})