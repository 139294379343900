import React from 'react';
import { PrimaryButton } from "@ruby-labs/ruby-web-common";

import { useStyles } from "./styles";

export const ContinueButton = ({ onClick }: any) => {
    const { buttonWrapper, button } = useStyles();

    return (
        <div className={buttonWrapper}>
            <PrimaryButton
                className={button}
                onClick={onClick}
                label="Next"
            />
        </div>
    );
};
