import React, {useEffect} from 'react';
import * as _ from 'lodash';

import {CheckButton} from "@ruby-labs/ruby-web-common";
import {isButtonClearShouldBeNotActive, isButtonTypeClear} from "../../../../utils/buttons_conditions";
import {useLocalStorage} from "../../../../helpers/hooks/local_storage.hook";

export const ButtonMultiSelectLogic = ({ className, path, type, event,  ...props }: any) => {
    const {pageValue, setPageValue} = useLocalStorage({ key: path, defaultValue: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const newValue = [props.value || props.label, props.value || props.label]

    const buttonClicked = () => {

        if (isButtonTypeClear(type) && pageValue.includes(newValue[0])) {
            setPageValue([]);
        } else if (isButtonTypeClear(type)) {
            setPageValue(pageValue ? [newValue[0]] : [newValue[0]]);
        } else {
            setPageValue(pageValue ? _.xor(pageValue, newValue) : [newValue[0]]);
        }
    }

    useEffect(() => {
        if (isButtonClearShouldBeNotActive(type, pageValue, newValue)) {
            setTimeout(() => {
                setPageValue(pageValue ? _.xor(pageValue, [newValue[0]]) : [newValue[0]]);
            }, 100)
        }
    }, [type, pageValue, newValue, setPageValue])

    return (
        <div className={className}>
            <CheckButton
                centered={true}
                selected={pageValue && pageValue.includes(props.value || props.label)}
                onClick={buttonClicked}
                {...props}
            />
        </div>
    );
}
