import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    header: {
        padding: '16px 21px 2px',
        textAlign: 'center',
        minHeight: 58,
        borderBottom: '1px solid #DEE5F9'
    },
    content: {
        textAlign: 'center',
        padding: ({isProgressBarActive}: any) => !isProgressBarActive ? 0: 32
    }
})
