import React from "react";

import { WeightGraphic } from "../../../components/weight_graphic";
import {Typography, TypographyVariants, useLocalStorage} from "@ruby-labs/ruby-web-common";

import icCheck from '../../../assets/svg/ic_check.svg'
import { useStyles } from "./styles";

const GOAL_TITLES = {
    'loseWeight': 'weight loss',
    'gainMuscle': 'muscle gain',
    'getFit': 'fitness'
}

export const HighLightList = () => {
    const {
        listItem, highLightList, listItemText,
        title, chart
    } = useStyles();
    const { pageValue } = useLocalStorage({ key: 'body-goal', defaultValue: '' })


    return (
        <div className={highLightList}>
            <Typography className={title}  variant={TypographyVariants.h3} >
                Highlights of your plan:
            </Typography>
            <WeightGraphic className={chart} />
            <div className={listItem}>
                <img src={icCheck} alt=""/>
                {/*@ts-ignore*/}
                <Typography className={listItemText} variant={TypographyVariants.body1}>Focusing on healthy {GOAL_TITLES[pageValue]}</Typography>
            </div>
            <div className={listItem}>
                <img src={icCheck} alt=""/>
                <Typography className={listItemText} variant={TypographyVariants.body2}>
                    Tailored to your busy lifestyle
                </Typography>
            </div>
            <div className={listItem}>
                <img src={icCheck} alt=""/>
                <Typography className={listItemText} variant={TypographyVariants.body1}>
                    Easy-to-follow nutrition guidance
                </Typography>
            </div>
            <div className={listItem}>
                <img src={icCheck} alt=""/>
                <Typography className={listItemText} variant={TypographyVariants.body1}>
                    1:1 chat with a personal coach
                </Typography>
            </div>
        </div>
    )
};
