import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { localStorageService, useLocalStorage } from "@ruby-labs/ruby-web-common";

import { SignUpApiProvider } from "../../../api/sign_up.api";

import { PaymentKeyApiProvider } from "../../../api/payment_key";

type AddonPlan = {
    [key: string]: string
}

const ADDON_PLANS: AddonPlan = {
    '1_week_19_1-USD-Weekly': 'setup_fee_1-USD',
    '1_week_19_5-USD-Weekly': 'setup_fee_5-USD',
    '1_week_19_9-USD-Weekly': 'setup_fee_9-USD',
    '1_week_19_13-USD-Weekly': 'setup_fee_13-USD'
};

export const usePaywallInfo = () => {
    const [stripeProp, setStripeProp] = useState<any>({});
    const [stripePromise, setStripePromise] = useState<any>(null);
    const [loadedKeys, toggleLoadedKeys] = useState(false);
    const {
        pageValue: addon,
    } = useLocalStorage({ key: "planCode", defaultValue: '' });
    const {
        pageValue: price,
    } = useLocalStorage({ key: "planCodeLabel", defaultValue: '' });
    const {
        pageValue: countryCode
    } = useLocalStorage({ key: "utm_country", defaultValue: '' });
    const {
        pageValue: checkoutPayKey
    } = useLocalStorage({ key: 'checkoutPayKey', defaultValue: '' });

    useEffect( () => {
        if(countryCode) {

            SignUpApiProvider.getAddonPrice(ADDON_PLANS[addon] || null, countryCode).then(
                ({ data: { price, currency_code } }) => {
                    setStripeProp({ price, currency: currency_code });
                }
            );

            PaymentKeyApiProvider.getPaymentKeys({
                countryCode,
                amount: {
                    currency: "USD",
                    value: 0,
                },
            }).then((res) => {
                const paymentMethods = res.data?.adyenPaymentMethods?.paymentMethods;
                const merchant_id = paymentMethods?.find((p: any) => p.type === "applepay")
                    ?.configuration?.merchantId;
                const stripePublishableKey = res.data?.stripePublishableKey as string;
                const checkoutPublishableKey = res.data?.checkoutPublicKey as string;

                localStorageService.setItem("stripePublishableKey", stripePublishableKey);
                localStorageService.setItem("merchant_id", merchant_id);
                localStorageService.setItem('checkoutPayKey', checkoutPublishableKey);

                setStripePromise(loadStripe(res.data?.stripePublishableKey as string));
                toggleLoadedKeys(true);
            });
        }
    }, [countryCode]);

    return {
        price,
        stripeProp,
        countryCode,
        loadedKeys,
        accessPaymentsKeys: {
            checkoutPayKey,
            stripePromise,
            braintreeAuthorization: process.env.REACT_APP_BRAINTREE_AUTHORIZATION as string,
            amazonSellerId: process.env.REACT_APP_AMAZON_PAY_SELLER_ID as string,
            amazonClientId: process.env.REACT_APP_AMAZON_PAY_CLIENT_ID as string
        }
    }
}