import React from "react";
import { useHistory } from 'react-router-dom';
import logo from "../../../assets/svg/logotype.svg";
import back from "../../../assets/svg/back_circle.svg";
import { useStyles } from "./styles";

export const HeaderLogo = ({...props}) => {
    const history = useHistory();
    const { headerLogoClass, backButton, logoClass, centerLogo } = useStyles();
    const backButtonVisible = props.back;
    const logoClassStyle = `${logoClass} ${backButtonVisible ? centerLogo : ''}`;
    const handleBack = () => history.goBack();

    return (
        <div className={headerLogoClass}>
            {backButtonVisible && <img className={backButton} onClick={handleBack} src={back} alt="" />}
            <img className={logoClassStyle} src={logo} alt=""/>
        </div>
    );
};
