import React, {useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {SelectButton} from "@ruby-labs/ruby-web-common";
import {BaseContext} from "../../../../helpers/context/base.context";
import {useLocalStorage} from "../../../../helpers/hooks/local_storage.hook";
import {GenericInstanceCTX} from "../../../../store/generic_store";

export const ButtonSelectLogic = ({ className, path, destinationPath, event,  ...props }: any) => {
    let history = useHistory();
    const {analytic} = useContext(BaseContext);
    const {pageValue, setPageValue} = useLocalStorage({ key: path, defaultValue: '' })
    const { startPreloadPage } = useContext(GenericInstanceCTX)

    useEffect(() => {
        startPreloadPage(destinationPath);
    },[startPreloadPage, destinationPath])

    const buttonClicked = () => {
        if(event) analytic?.event(event);
        setPageValue(props.value || props.label);
        setTimeout(() => {
            history.push(destinationPath);
        }, 300)
    }

    return (
        <div className={className}>
            <SelectButton
                centered={true}
                selected={props.value === pageValue}
                onClick={buttonClicked}
                {...props}
            />
        </div>
    );
}
