import {WrapperApi} from "./wrapper.api";

class SignUpApi extends WrapperApi{
    signUp(data: any): Promise<any> {
        return this.post('/signup/web', {...data, marketingCampaign: 'mailWinback'})
    }
    updatePayment(data: any) {
        return this.post('/chargebee/payment-source', data, 2);
    }
    getAddonPrice(chargeID: string | null, country: string): Promise<any> {
        return this.get(`chargebee/charge`, { chargeID, country });
    }
    getInvoices(ID: string): Promise<any> {
        return this.get(`chargebee/last-transaction/${ID}`);
    }
}

export const SignUpApiProvider = new SignUpApi();
