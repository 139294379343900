import moment from "moment";

type UnitStepsType = { kg: number; lb: number; }

type MaxWeightDIffType = { kg: number, lb: number }

type PropsType = {
    goalWeight: number,
    weight: number;
    unit: 'kg' | 'lb';
    goal: 'get fit' | 'lose weight' | 'gain muscle'
}

export class BodyPlanCalculator {
    months: Array<string>;
    unitSteps: UnitStepsType;
    maxWeightDiff: MaxWeightDIffType;
    goalWeight: number;
    weight: number;
    unit: 'kg' | 'lb';
    goal: 'get fit' | 'lose weight' | 'gain muscle';
    fullPoints: Array<any>

    constructor({ goalWeight, weight, unit, goal = 'get fit' }: PropsType) {
        this.goal = weight === goalWeight ? 'get fit' : goal;
        this.goalWeight = goalWeight;
        this.weight = weight;
        this.unit = unit;
        this.months = moment.monthsShort();
        this.unitSteps = { kg: 4, lb: 8 };
        this.maxWeightDiff = { kg: 44, lb: 88 };
        this.fullPoints = [];
    }
    getData = () => {
        const getter = {
            'get fit': this.getFitData,
            'lose weight': this.getPlanData,
            'gain muscle': this.getPlanData
        }

       return getter[this.goal]();
    }
    getFullPoints = () => {
        this.getData();

        return this.fullPoints;
    }
    getPlanData = () => {
        const step = this.unitSteps[this.unit];
        const maxWeight = this.maxWeightDiff[this.unit];
        const weightDiff = this.weight - this.goalWeight;
        const descending = weightDiff > 0;
        const absWeightDiff = Math.abs(weightDiff);
        const correctWeightDiff = absWeightDiff > maxWeight ? maxWeight : absWeightDiff;
        const correctGoalWeight = descending ? this.weight - correctWeightDiff : this.weight + correctWeightDiff;
        const dirtySteps = Math.floor(correctWeightDiff/step) + 1;
        const points = new Array(dirtySteps).fill(true).map((_, i) => ({
            name: this.getMonth(i),
            uv: descending ? this.weight - step * i: this.weight + step * i
        }))

        if (correctWeightDiff % step) {
            points.push({ name: this.getMonth(points.length), uv: correctGoalWeight });
        }

        this.fullPoints = points;
        const sortedData = this.getShowedPoints(points);

        sortedData.unshift({...sortedData[0], name: ''});
        if (sortedData.length === 1){
            sortedData.push({ name: '', uv: descending ? correctGoalWeight - 30: correctGoalWeight + 30, fd: 100 });
        } else {
            sortedData.push({...sortedData[sortedData.length - 1], name: ''});
        }

        return sortedData;
    }
    getFitData = () => {
        return [
            {name: '', uv: 5, fd: 10},
            {name: this.getMonth(1), uv: 10, fd: 10},
            {name: this.getMonth(2), uv: 15, fd: 15},
            {name: this.getMonth(3), uv: 25, fd: 25},
            {name: '', uv: 30, fd: 25},
        ]
    }
    getShowedPoints = (points: any) => {
        const lastChild = points[points.length - 1];

        if (points.length <= 5) return this.getFakePoints(points);

        if (points.length === 6) {
            points.splice(1, 1);

            return this.getFakePoints(points);
        }

        if (points.length === 7 || points.length === 9) {
            return this.getFakePoints(points.filter((_: any, i: number) =>  !(i % 2)))
        }

        if (points.length === 8) {
            const showedPoints = points.filter((_: any, i: number) => !(i % 2));

            showedPoints.push(lastChild);

            return this.getFakePoints(showedPoints);
        }

        if (points.length === 10 || points.length === 13) {
            return this.getFakePoints(points.filter((_: any, i: number) => !(i % 3)))
        }

        if (points.length === 11 || points.length === 12) {
            const showedPoints = points.filter((_: any, i: number) => !(i % 3));

            showedPoints.push(lastChild);

            return this.getFakePoints(showedPoints);
        }
    }
    getFakePoints = (points: any) => {
        const descending: boolean = (this.weight - this.goalWeight) > 0

        if (points.length === 1) {
            return points.map((item: any) => {
                return {
                    ...item,
                    fd: 100,
                }
            })
        } else if (points.length === 2) {
            return points.map((item: any, i: number) => {
                if (descending) {
                    return {
                        ...item,
                        fd: i === 1 ? 50 : 100,
                    }
                } else {
                    return {
                        ...item,
                        fd: i === 1 ? 100 : 50,
                    }
                }
            })
        } else if (points.length === 3) {
            return points.map((item: any, i: number) => {
                if (descending) {
                    return {
                        ...item,
                        fd: i === 2 ? 50 : 100 - i * 35,
                    }
                } else {
                    return {
                        ...item,
                        fd: i === 0 ? 40 : i === 1 ? 60 : 100,
                    }
                }
            })
        } else if (points.length === 4) {
            return points.map((item: any, i: number) => {
                if (descending) {
                    return {
                        ...item,
                        fd: i === 2 ? 20 : 100 - i * 35,
                    }
                } else {
                    return {
                        ...item,
                        fd: i === 0 ? 20 : i * 35,
                    }
                }
            })
        } else if (points.length === 5) {
            return points.map((item: any, i: number) => {
                if (descending) {
                    return {
                        ...item,
                        fd: i === points.length - 1 ? 35 : i === 2 ? 55 : i === 3 ? 40 : 100 - i * 20,
                    }
                } else {
                    return {
                        ...item,
                        fd: i === points.length - 1 ? 100 : i === 1 ? 25 : i === 2 ? 45 : i * 20 + 20,
                    }
                }
            })
        }
    }
    getMonth = (month: any = 0) => {
        const monthNumber = Number(moment().add(month - 1, 'M').format('M'));
        const correctMonth = monthNumber > 11 ? 0 : monthNumber;
        return this.months[correctMonth];
    }
}
