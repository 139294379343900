import {useEffect, useState} from "react";
import keys from 'lodash/keys';
import queryString from 'query-string';

import {ProfileApiProvider} from "../../api/profile.api";
import {localStorageService} from "@ruby-labs/ruby-web-common";


const possiblePlans: { [key: string]: any } = {
    '1': {
        planCode: "1_week_19_1-USD-Weekly",
        price: "1",
        currency: "USD"
    },
    '5': {
        planCode: "1_week_19_5-USD-Weekly",
        price: "5",
        currency: "USD"
    },
    '9': {
        planCode: "1_week_19_9-USD-Weekly",
        price: "9",
        currency: "USD"
    },
    '13': {
        planCode: "1_week_19_13-USD-Weekly",
        price: "13.21",
        currency: "USD"
    }
}

const KEYS = {
    web_user_activity: 'habits-activity',
    web_user_loss_speed: 'nutrition-pace',
    user_plan_id: 'userID',
    web_user_idealweight: 'body-idealweight',
    web_user_age: 'body-age',
    web_user_gender: 'body-gender',
    web_user_goal: 'body-goal',
    web_user_weight: 'body-weight',
    web_user_height: 'body-height',
    web_user_unit: 'unit',
    email: 'email'
}

export const useLoadData = () => {
    const [subscribed, setSubscribe] = useState(false);
    const { planid, plan, userID } = queryString.parse(window.location.search);

    useEffect(() => {
        const storeProfileData = async () => {
            const { data, subscribed } = await ProfileApiProvider.getProfileData((planid || userID) as string);

            if(!data) return;

            setSubscribe(subscribed);

            keys(data).forEach((key: string) => {
                //@ts-ignore
                const correctKey = KEYS[key];

                if(correctKey) {
                    localStorageService.setItem(correctKey, data[key])
                }
            });


            if (plan && possiblePlans[plan as string]) {
                localStorageService.setItem('planCode', possiblePlans[plan as string]?.planCode);
                localStorageService.setItem('planCodeLabel', possiblePlans[plan as string]?.price)
            }
        }

        storeProfileData()
    }, [planid, userID]);

    return {
        isPlanValid: Boolean(plan && possiblePlans[plan as string]),
        subscribed
    }
}