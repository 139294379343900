import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    headerLogoClass: {
        padding: '12px 0',
        borderBottom: '1px solid #DEE5F9',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center'
    },
    logoClass: {
        flexGrow: 1,
        width: '87px',
        height: '32px'
    },
    centerLogo: {
        marginRight: 40
    },
    backButton: {
        marginLeft: 16,
        height: 24,
        width: 24
    }
});
