export const WEIGHT_LOSE_BODY_GOAL = 'loseWeight';
export const GAIN_MUSCLE_BODY_GOAL = 'gainMuscle';
const GET_FIT_BODY_GOAL = 'getFit'

export const longValueGetter = {
    [WEIGHT_LOSE_BODY_GOAL]: 'lose almost 40% more weight.',
    [GAIN_MUSCLE_BODY_GOAL]: 'gain almost 40% more muscle.',
    [GET_FIT_BODY_GOAL]: 'get fit almost 40% faster.'
};

export const valueGetter = {
    [WEIGHT_LOSE_BODY_GOAL]: '40% more weight loss!',
    [GAIN_MUSCLE_BODY_GOAL]: '40% more muscle gained!',
    [GET_FIT_BODY_GOAL]: '40% faster'
}

export type GoalTypes = 'loseWeight' | 'gainMuscle' | 'getFit';
