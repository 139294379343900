import React from "react";

import { ThemeProvider } from "@ruby-labs/ruby-web-common";

import { themeV2 } from "../../theme";

export const CustomThemeProvider = ({ children }: any) => {

    return (
        //@ts-ignore
        <ThemeProvider theme={themeV2}>
            {children}
        </ThemeProvider>
    )
};